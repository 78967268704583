import { Injectable } from "@angular/core";
import { AbstractNotifier } from "./abstract-notifier.service";
import { BehaviorSubject, Observable } from "rxjs";
import { SettingService } from "./setting.service";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    _notifications: {[key: string]: BehaviorSubject<string[]>} = {};
    notifiers: {[key: string]: AbstractNotifier} = {};

    register(notifier: AbstractNotifier) {
        notifier.notificationLabels.forEach( label => {
            this.notifiers[label] = notifier;
            this._notifications[label] = new BehaviorSubject([])
        })
    }

    registerAll(notifiers: AbstractNotifier[]) {
        notifiers.forEach( notifier => this.register(notifier));
    }

    refresh(label: string): Observable<string>|undefined {
        if(Object.keys(this.notifiers).includes(label)) {
            this.notifiers[label].refresh(label);
        }
        return undefined;
    }

    notify(notifier: AbstractNotifier, label: string, value: string[]) {
        if (this.notifiers[label] === notifier) {
            this._notifications[label].next(value);
        }
    }

    notifications(label: string): BehaviorSubject<string[]>|undefined {
        if (Object.keys(this._notifications).includes(label)) {
            return this._notifications[label]
        }
        return undefined
    }
}