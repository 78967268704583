import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Order } from '../model/order.model';
import { AbstractNotifier } from 'app/anapaya/service/abstract-notifier.service';
import { AbstractService } from 'app/anapaya/service/abstract.service';
import { CompanyService } from 'app/anapaya/service/company.service';
import { NotificationService } from 'app/anapaya/service/notification.service';
import { UserService } from 'app/anapaya/service/user.service';

import { environment } from 'environments/environment';
import { Observable, catchError, map, of } from 'rxjs';
import { addMinutes, addWeeks, compareAsc, formatISO, parseISO } from 'date-fns';
import { DealStatus } from '../model/deal.model';


export enum OrderStatus {/*eslint-disable */
  DRAFT = 'draft',
  SENT = 'sent',
  //REQUESTED= 'requested',
  ACKNOWLEDGED = 'acknowledged',
  CONFIGURATION_CONFIRMED = 'configuration_confirmed',
  DELIVERY_REQUESTED = 'delivery_requested',
  DELIVERY_PERFORMED = 'delivery_performed',
  DELIVERY_ACKNOWLEDGED = 'delivery_acknowledged',
  DELIVERED = 'delivered',
  REJECTED = 'rejected',
}/* eslint-enable */


export enum OrderStepStatus {/*eslint-disable */
  NOT_DONE= 'not_done', // no date field, boolean set to false
  PENDING='pending', // a date is set but corresponding bool field is still false
  DONE='done' // both date field + boolean are set
}/* eslint-enable */


export enum OrderStep { /*eslint-disable */
  SENT = 'isSent',
  ACKNOWLEDGED = 'isAcknowledged',
  CONFIGURATION_CONFIRMED= 'isConfigurationConfirmed',
  DELIVERY_REQUESTED = 'isDeliveryRequested',
  DELIVERY_PERFORMED = 'isDeliveryPerformed',
  DELIVERY_ACKNOWLEDGED = 'isDeliveryAcknowledged',
}/* eslint-enable */


@Injectable({
  providedIn: 'root'
})
export class OrderService implements AbstractService<Order>, AbstractNotifier{

  public notificationLabels: string[] = [
    'resellerDealOrder.sent',
    'resellerDealOrder.awaitsConfiguration',
    'resellerDealOrder.acknowledged',
    'resellerDealOrder.deliveryPerformed',
  ];

  public statuses = {
    'draft': 'Draft',
    'requested': 'Requested',
    'acknowledged': 'Acknowledged',
    'delivered': 'Delivered'
  };

  delayInMinutes = 5;

  dateFields: {[key in OrderStep]: string} = {
    [OrderStep.SENT]: 'sentAt',
    [OrderStep.ACKNOWLEDGED]: 'acknowledgedAt',
    [OrderStep.CONFIGURATION_CONFIRMED]: 'configurationConfirmedAt',
    [OrderStep.DELIVERY_REQUESTED]: 'deliveryRequestedAt',
    [OrderStep.DELIVERY_ACKNOWLEDGED]: 'deliveryAcknowledgedAt',
    [OrderStep.DELIVERY_PERFORMED]: 'deliveryPerformedAt'
  };

  constructor(
    private http: HttpClient,
    private companyService: CompanyService,
    private userService: UserService,
    public notificationService: NotificationService
  ) { }

  read(deliveryId: string): Observable<Order> {
    return this.http.get<{resellerDealOrder: Order}>(
      `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/reseller-deal-orders/${deliveryId}`, {withCredentials: true}).pipe(
      catchError(
        (err) => {
          throw err;
        }
      ),
      map(
        response =>
          response.resellerDealOrder
      ));
  }

  list(
    search?: string|{[key: string]: string|string[]|Date},
    sort?: {[key: string]: 'asc'|'desc'},
    offset: number=0,
    limit: number=20,
    options?: {[key: string]: string}
  ): Observable<{items: Order[]; count: number}> {

    const params = { limit, offset };

    if(typeof search === 'string') {
      params['where[name]']=`$ilike:${search}`;
    } else if (search !== undefined) {
      Object.keys(search).forEach((key) => {
        if(typeof search[key] === 'string') {
          params[`where[${key}]`]=`$ilike:${search[key]}`;
        } else if( Array.isArray(search[key]) ){
          const values: string[] = search[key] as string[];
          params[`where[${key}]`] = `$in:${values.join(',')}`;
        } else if (search[key] instanceof Date) {
          params[`where[$or][${key}]`] = `$gt:${formatISO (search[key] as Date)}`;
          params[`where[$or][${key}]`] = '$null:true';
        }
      });
    }

    if(options?.section === 'reseller') {
      params['where[resellerCompanyId]']=`${this.companyService.currentCompany.id}`;
    } else {
      params['where[providerCompanyId]']=`${this.companyService.currentCompany.id}`;
    }

    //managing sort order
    if(sort) {
      const key = Object.keys(sort)[0];
      params[`order[${key}]`]=sort[key];
    }
    return this.http.get<{resellerDealOrders: Order[]; count: number}>(`${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/reseller-deal-orders`, {
      withCredentials: true,
      params: params
    }).pipe(
      catchError(
        (err) => {
          throw err;
        }
      ),
      map(
        response => ({items: response.resellerDealOrders, count: response.count})
      )
    );
  }

  post(delivery): Observable<Order> {
    return this.http.post<{resellerDealOrder: Order}>(
      `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/reseller-deal-orders/`, delivery, {withCredentials: true}).pipe(
      map ( response => response.resellerDealOrder)
    );
  }

  patch(dealId: string, deal: Order): Observable<Order> {
    return this.http.patch<{resellerDealOrder: Order}>(
      `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/reseller-deal-orders/${dealId}`, deal, {withCredentials: true}).pipe(
      map( response => response.resellerDealOrder)
    );
  }

  delete(dealId: string): Observable<boolean> {
    return this.http.delete<any>(`${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/reseller-deal-orders/${dealId}`, {withCredentials: true}).pipe(
      map( response => true)
    );
  }

  canWrite(): boolean {
    return !this.companyService.currentCompany ?
      false :
      (this.companyService.currentCompany.permission.admin || this.userService.userPermissionForPath(this.companyService.currentCompany.id, 'resellerDealOrders', 'write'));
  }

  canWriteGATE(): boolean {
    return !this.companyService.currentCompany ?
      false :
      (this.companyService.currentCompany.permission.admin || this.userService.userPermissionForPath(this.companyService.currentCompany.id, 'gateOrders', 'write'));
  }

  canSend(): boolean {
    return !this.companyService.currentCompany ? false : (this.userService.userPermissionForPath(this.companyService.currentCompany.id, 'resellerDealOrders', 'send'));
  }

  canAcknowledge(): boolean {
    return !this.companyService.currentCompany ? false : (this.userService.userPermissionForPath(this.companyService.currentCompany.id, 'resellerDealOrders', 'acknowledge'));
  }

  /*eslint-disable */
  refresh(label?: string) {
    if(label === 'resellerDealOrder.sent') {
      return this.http.get<{resellerDealOrders: Order[]; count: number}>(
        `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/reseller-deal-orders?where['isSent']=true&where['isAcknowledged']=false&where['providerCompanyId']=${this.companyService.currentCompany.id}`, {
        withCredentials: true,
      }).subscribe(
        (response) => {
          this.notify(label, response.resellerDealOrders.length >= 0 ? response.resellerDealOrders.map(order => order.id):[]);
        }
      );
    } else if ( label === 'resellerDealOrder.awaitsConfiguration' ) {
      return this.http.get<{resellerDealOrders: Order[]; count: number}>(
        `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/reseller-deal-orders?where['isConfigurationConfirmed']=true&where['isDeliveryRequested']=false&where['providerCompanyId']=${this.companyService.currentCompany.id}`, {
        withCredentials: true,
      }).subscribe(
        (response) => {
          this.notify(label, response.resellerDealOrders.length >= 0 ? response.resellerDealOrders.map(order => order.id):[])
        }
      );
    } else if ( label ===  'resellerDealOrder.acknowledged' ) {
      return this.http.get<{resellerDealOrders: Order[], count: number}>(`${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/reseller-deal-orders?where['isAcknowledged']=true&where['isConfigurationConfirmed']=false&where['providerCompanyId']=$ne:${this.companyService.currentCompany.id}`, {
        withCredentials: true,
      }).subscribe(
        (response) => {
          this.notify(label, response.resellerDealOrders.length >= 0 ? response.resellerDealOrders.map(order => order.id):[])
        }
      );
    } else if ( label === 'resellerDealOrder.deliveryPerformed' ) {
      return this.http.get<{resellerDealOrders: Order[], count: number}>(`${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/reseller-deal-orders?where['isDeliveryPerformed']=true&where['isDeliveryAcknowledged']=false&where['providerCompanyId']=$ne:${this.companyService.currentCompany.id}`, {
        withCredentials: true,
      }).subscribe(
        (response) => {
          this.notify(label, response.resellerDealOrders.length >= 0 ? response.resellerDealOrders.map(order => order.id):[])
        }
      );
    }
    return undefined;
  }/*eslint-enable */

  defaultListParameters(): {resellerDealStatus: DealStatus[]; deliveryPerformedAt: Date } {
    return {
      resellerDealStatus: [DealStatus.CLOSED_WON],
      deliveryPerformedAt: addWeeks(new Date(), -2)
    };
  }

  notify(label: string, value: string[]): void {

    this.notificationService.notify(this, label, value);
  };

  getStepStatus(order: Order, step: OrderStep, section: 'provider'|'reseller'): OrderStepStatus {

    if(!order[this.dateFields[step]]) {//date field not set: not done
      return OrderStepStatus.NOT_DONE;
    } else if (order[this.dateFields[step]] !== undefined && !(compareAsc(new Date(), (parseISO(order[this.dateFields[step]]))) >= 0 || order[step] === true)) {
      //date field is set and action in the future
      if(
        ([OrderStep.CONFIGURATION_CONFIRMED, OrderStep.SENT, OrderStep.DELIVERY_REQUESTED].includes(step) && section === 'provider') ||
        ([OrderStep.ACKNOWLEDGED, OrderStep.DELIVERY_PERFORMED, OrderStep.DELIVERY_ACKNOWLEDGED].includes(step) && section === 'reseller')
       ) {

        return OrderStepStatus.DONE;
       }

       return OrderStepStatus.PENDING;

    }
    //date is set but step field not set to true and date is still in the future : pending
    return OrderStepStatus.DONE;
  }

  getStepDate(order: Order, step: OrderStep, section: 'provider'|'reseller'): Date|undefined {
    if(order[this.dateFields[step]]) {
      if(
        ([OrderStep.CONFIGURATION_CONFIRMED, OrderStep.SENT, OrderStep.DELIVERY_REQUESTED].includes(step) && section === 'provider') ||
        ([OrderStep.ACKNOWLEDGED, OrderStep.DELIVERY_PERFORMED, OrderStep.DELIVERY_ACKNOWLEDGED].includes(step) && section === 'reseller')
       ) {
        if(order[step] || compareAsc(new Date(), (parseISO(order[this.dateFields[step]]))) >= 0) {
          return parseISO(order[this.dateFields[step]]);
        }
        return undefined;
       } else {
        return addMinutes (parseISO(order[this.dateFields[step]]), -this.delayInMinutes);
       }
    }
    return undefined;
  }

}
