import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { CompanyService } from '../service/company.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanActivateChild {
  constructor(
    private companyService: CompanyService
  ) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this._setMaster();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this._setMaster()
  }

  _setMaster() {
    return this.companyService.companies.pipe(
      map(
        companies => {
          let isMaster = false;
          companies.forEach( company => {

            if(company.isMasterCompany) {
              isMaster = true;

              if(this.companyService.currentCompany?.id !== company.id) {
                this.companyService.currentCompany = company
              }
            }
          });

          return isMaster;
        }
      )
    )
  }
  
}
