import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DisplayTemplate, DisplayTemplateField } from '../model/display-template.model';
import { Feature, SubFeature } from '../model/feature.model';
import { FieldConfig } from '../form-config/form-config.model';


@Component({
  selector: 'permission-edit',
  templateUrl: './permission-edit.component.html',
  styleUrls: ['./permission-edit.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: PermissionEditComponent
    }
  ]
})
export class PermissionEditComponent implements OnInit, ControlValueAccessor {

  permissionForm: FormGroup;
  permissions: {[key: string]: Feature};
  @Input() displayTemplate: DisplayTemplate[];
  @Input() template: {[key: string]: any};
  displayReadWriteHeader = false;

  onChange = (permission) => {};
  onTouched = () => {};

  constructor(
  ) { }

  ngOnInit(): void {
    this.permissionForm = new FormGroup({});
  }

  _initForm() {
    this.displayTemplate.forEach( bloc => {
      let requiring: [DisplayTemplateField, DisplayTemplateField][] = [];      
      bloc.fields.forEach(field => {
        if(field.permissionField && this._access(field.permissionField, field.isReadWrite ? "read" : field.fields[0])) {
            let fields: string[] = [];
            
            //show/hide read/write labels
            if(field.isReadWrite) {
              this.displayReadWriteHeader = true;
            }
            
            if(field.isReadWrite) {
              if(this._access(field.permissionField, "write") === undefined) {
                fields = ["read"]
              } else {
                fields = ["read", "write"];
              }

            } else if (field.fields) {
              fields = field.fields.filter( f => f !== undefined);
            }
            
            let group: {[key: string]: FormControl} = {}
            fields.forEach( f => {
              let fValue = this._read(field.permissionField, f);
              group[f] = new FormControl(fValue ?? false)
            });

            let fieldGroup = new FormGroup(group);

            
            // here we add a watcher on the write commuter that turns the 'read' commuter to true
            // if write is true in order to avoid havid a {read: false, write: true} accress rights pair
            // which is nonsense. This is only done if both read and write rights are present
            if (field.isReadWrite && fields.length === 2) {
              fieldGroup.controls["write"].valueChanges.subscribe(
                value => {
                  if(value) {
                    fieldGroup.controls["read"].patchValue(true)
                  }  
                }
              )
              fieldGroup.controls["read"].valueChanges.subscribe(
                value => {
                  if(!value) {
                    fieldGroup.controls["write"].patchValue(false)
                  }  
                }
              )
            } else if(field.fields) {

            }

            if (field.requires) {
              let required = bloc.fields.find(itemS => {return itemS.permissionField === field.requires[0] && itemS.fields.includes(field.requires[1])})
              requiring.push([required, field])
            }

            // a right on current toplevel permission might already have been added. If this is the case, current form controls
            // are added to existing formgroup
            if (field.permissionField,Object.keys(this.permissionForm.controls).some(key => key === field.permissionField)) {
              Object.keys(fieldGroup.controls).forEach( key => {
                (this.permissionForm.controls[field.permissionField] as FormGroup).addControl(key, fieldGroup.controls[key])
              })
              
            } else {
              //otherwise we create it
              this.permissionForm.addControl(
                field.permissionField, fieldGroup
              );
            }

          } else {
            field.isShown = false;
          }
        }
      );
      //building enable/disable automatic pairing where required
      requiring.forEach( requiredLink => {
        if(requiredLink[0] && requiredLink[1]) {;
          
          let control2 = (this.permissionForm.controls[requiredLink[1].permissionField] as FormGroup)
            .controls[requiredLink[1].fields[0]] as FormControl<any>;
          let control1 = (this.permissionForm.controls[requiredLink[0].permissionField] as FormGroup)
            .controls[requiredLink[0].fields[0]] as FormControl<any>;

          this.createDependency(control1, control2)
        }
      })

      //hide bloc if nothing to see
      if(!bloc.fields.some( bloc => bloc.isShown)) {
        bloc.isShown = false;   
      }
    });

    this.permissionForm.valueChanges.subscribe( perm => {
      Object.keys(perm).forEach( key => {
        Object.keys(perm[key]).forEach( verb => {
          this._write(key, verb, perm[key][verb]);
        })
        
      })
      this.onChange(this.permissions)
    })
  }

  createDependency(control1: FormControl, control2: FormControl) {
    control2.valueChanges.subscribe(
      value => {
        if(value) {
          control1.patchValue(true)
        }  
      }
    )
    control1.valueChanges.subscribe(
      value => {
        if(!value) {
          control2.patchValue(false)
        }  
      }
    )
  }

  writeValue(permission: {[key: string]: Feature}): void {
    this.permissions = permission;
    this._initForm();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * with fine tuned permissions, some initially read/write rights could come as "read only" rights, wich we capture with the following test
   * @param ftName 
   * @returns 
   */
  _hasWrite(ftName: string): boolean {
    if(this.permissionForm.controls[ftName] instanceof FormGroup) {
      return Object.keys((this.permissionForm.controls[ftName] as FormGroup).controls), Object.keys((this.permissionForm.controls[ftName] as FormGroup).controls).includes("write")
    }
    return true
  }

  _read(path: string, mode: string) {
    let ft = this._access(path, mode);
    if(ft) {
      return ft[mode]
    }
    return undefined
  }

  _write(path: string, verb: string, value: boolean) {
    let ft = this._access(path, verb);
    if(ft) {
      ft[verb] = value;
    }
  }

  _isFeature(object: any, verb: string): object is Feature {
    
    return object !== null && object !== undefined && (
      (typeof object === "object" && Object.keys(object).includes("read") && Object.keys(object).includes("write")) ||
      (verb !== undefined && Object.keys(object).includes(verb) && typeof object[verb] === "boolean") ||
      (typeof object === 'boolean'))
  } 

  _access(path: string, verb: string): Feature|undefined {
    
    let parts = path ? path.split('.') : [];
    let obj: {[key: string]: Feature}|Feature|SubFeature|boolean = this.permissions;
    parts.forEach(
      a => {

        if (obj!== null && obj !== undefined) {
          obj = obj[a]
        }
      }
    );
    return this._isFeature(obj, verb) ? obj : undefined;
  }

  _fieldIsReadWrite(path: string): boolean {
    let isrw = false;
    this.displayTemplate.forEach( tplElem => {
      tplElem.fields.filter( el => {
        el.permissionField === path
      }). forEach( field => {
        if(field.isReadWrite) {
          isrw = true;
        }
      });
    })

    return isrw;
  }

}
