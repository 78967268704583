import { Company } from 'app/anapaya/model/company.model';
import { Order } from './order.model';


export enum DealStatus {
    /*eslint-disable*/
    OPPORTUNITY = 'opportunity',
    PROPOSAL_SENT = 'proposalSent',
    CLOSING = 'closing',
    CLOSED_WON = 'closedWon',
    CLOSED_LOST = 'closedLost',
    REQUESTED = 'requested'
    /* eslint-enable */
}
export class Deal {
    public id?: string;
    public resellerCompanyId?: string;
    public resellerCompany?: Company;
    public endClientCompanyId?: string;
    //public providerComany?: Company;
    public reference?: string;
    public status?: DealStatus;
    public createdAt?: Date;
    public name?: string;
    public orders?: Order[];
    public endClient?: Company;
    public quantity?: number;
    public isConfidential?: boolean;
    public comment?: string;
    public scionAs?: string;
    public isFixedPriceRequested?: boolean;
    public isFixedPriceApproved?: boolean;
    public fixedPriceApprovalComment?: string;
    public ipPrefixes?: string;
    public term?: number;
}
