<div class="flex-auto p-6">
    <div class="flex flex-row mb-6 text-3xl gap-4">
        <span *ngIf="contact === undefined">
            New contact
        </span>
        <span *ngIf="contact !== undefined">
            {{contact.firstName}} {{contact.lastName}}
        </span>
        <span *ngIf="permissions?.admin" class="inline-bloc rounded-full px-2.5 py-0.5 h-6 text-sm w-15 bg-warn-400 mt-2">
            Admin
        </span>
    </div>
    <form [formGroup]="userForm" class="w-full py-7" *ngIf="userForm">
        <div class="flex flex-col">
            <div class="flex-auto">
                <mat-form-field class="w-full mat-mdc-form-field" appearance="fill">
                    <input matInput type="text" [formControlName]="'firstName'" placeholder="First name"/>
                </mat-form-field>
            </div>
            <div class="flex-auto">
                <mat-form-field class="w-full mat-mdc-form-field" appearance="fill">
 
                    <input matInput type="text" [formControlName]="'lastName'" placeholder="Last name"/>
                </mat-form-field>
            </div>
            <div class="flex-auto">
                <mat-form-field class="w-full mat-mdc-form-field" appearance="fill" [floatLabel]="true">   
                    <input matInput type="text" [formControlName]="'email'" placeholder="Email"/>
                </mat-form-field>
            </div>
        </div>

        <ng-container *ngIf="displayPermissions">
            <div class="mb-10 mt-4 text-3xl">Permissions</div>
            <permission-edit [template]="permTemplate" [displayTemplate]="permDisplayTemplate" [formControlName]="'permissions'" class="my-4">
            </permission-edit>
        </ng-container>
        
        <ng-container *ngIf="displayPermissions && isMaster()">
            <permission-edit [template]="permTemplate" [displayTemplate]="masterPermDisplayTemplate" [formControlName]="'permissions'" class="py-10">
            </permission-edit>
        </ng-container>
        <div class="flex items-center justify-end px-6 py-10 gap-3">
            <div>
                <mat-checkbox class="example-margin"
                    [formControlName]="'shouldReceiveInviteEmail'"
                    >
                    Notify user
                </mat-checkbox>
            </div>
            <button (click)="cancel()" mat-button="" class="mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base">
                <span class="mdc-button__label"> Cancel </span>
            </button>
            <button (click)="validate()" mat-flat-button="" class="px-6 ml-3 mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base">
                <span class="mat-mdc-button-persistent-ripple mdc-button__ripple"></span>
                <span class="mdc-button__label"> Save </span>
                <span class="mat-mdc-focus-indicator"></span>
                <span matripple="" class="mat-ripple mat-mdc-button-ripple"></span>
                <span class="mat-mdc-button-touch-target"></span>
            </button>
        </div>

     
    </form>
</div>

