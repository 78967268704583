import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, map, catchError } from 'rxjs';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AnapayaNoAuthGuard implements CanActivate,CanActivateChild, CanLoad  {
  
  constructor(private authService: AuthService, private router: Router) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._check();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this._check();
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this._check();
  }

  /**
   * checks if a user is logged in. If yes, redirects to app.
   * @returns 
   */
  _check() {
    return this.authService.isSignedIn().pipe(
      catchError( r => {
        return r;
      }),
      map (signedIn => {
        if(signedIn) {
          this.router.navigate(["/hub"])
        }
        return !signedIn;}),
      
    );

  }
  
}
