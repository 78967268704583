import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Company } from '../model/company.model';
import { Contact } from '../model/contact.model';
import { AuthService } from '../service/auth.service';
import { CompanyService } from '../service/company.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'anapaya-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  showAvatar = false;
  user: Contact & {status: string, avatar: string};
  company: Company|undefined;
  companies: Company[];

  constructor(
    private router: Router,
    private userService: UserService,
    private companyService: CompanyService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    // Subscribe to user changes
    this.userService.user$
      .subscribe((user: Contact|undefined) => {
        this.user = {...user, avatar: "", status: ""};
        // Mark for check
        this.changeDetectorRef.markForCheck();
      });
    this.company = this.companyService.currentCompany;
    this.companyService.companies$.subscribe(comps => {
      this.companies = comps?.filter(company => company.permission !== null );
     })

     this.companyService.currentCompany$.subscribe(company => {
      this.company = company
     })
  }

  get currentCompany() {
    return this.companyService.currentCompany;
  }

   /**
     * Sign out
     */
   signOut(): void
   {
    if(this.isIdentityAssumed()) {
      this.stopAssume()
    } else {
      this.router.navigate(['/sign-out']);
    }
   }

   stopAssume(): void {
    this.router.navigate(['/sign-out'], {queryParams: {assume: true, superAdminSection: `/company/`}});
   }

   isIdentityAssumed(): boolean {
    return this.userService.isIdentityAssumed
   }

   getCompanyLink(companyId): string[]{
    return ['/company/'+companyId];
   }  
}
