import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { CompanyChooserComponent } from '../company-chooser/company-chooser.component';
import { CompanyService } from '../service/company.service';

@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss']
})
export class CompanySearchComponent extends CompanyChooserComponent {
  @ViewChild(MatDrawer) drawer: MatDrawer;
  drawerOpened=false;
  constructor(
    protected override route: ActivatedRoute,
    protected override companyService: CompanyService,
    protected router: Router
  ) {
    super(route, companyService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.router.events.subscribe(
      event => {
        if(event instanceof NavigationStart && this.drawerOpened) {
          this.drawer.close()
        } 
      }
    );
    window.setTimeout(
      (that) => {
        if(that.route.children.length > 0) {
          this.drawerOpened = true;
          this.drawer.toggle();
        }
      },
      200,
      this
    );
  }

  closeAndBack() {
    this.drawerOpened = false;
    this.router.navigate(["./"], {relativeTo: this.route})
    this.drawer.close();
  }

  openDrawer(companyId) {
    this.router.navigate(['./', {outlets: {contacts: [companyId]}}], {relativeTo: this.route}).then(
      r => {
        this.drawer.open();
        this.drawerOpened = true;
      }
    )
  }
}
