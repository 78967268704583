<form [formGroup]="filterForm">
    <mat-form-field appearance="fill" class="w-full" [floatLabel]="true"
        class="mat-mdc-form-field fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50 mat-mdc-form-field-type-mat-input mat-mdc-form-field-has-icon-prefix mat-form-field-appearance-fill mat-primary">
        <mat-icon matPrefix>search</mat-icon>
        <mat-chip-list #filterList aria-label="Criterion selection" class="w-auto px-2">
            <mat-chip *ngFor="let crit of _criteria" (removed)="removeEvent(crit)" class="py-2 bg-primary-500" >
                {{labelFromName(crit.subject)}} {{crit.predicate}} {{objectLabel(crit.subject, crit.object)}}
                <button matChipRemove [attr.aria-label]="'remove ' + crit" class="py-0 text-xs text-white">
                    <mat-icon svgIcon="heroicons_outline:x" class="icon-smaller"></mat-icon>
                </button>
            </mat-chip>
            <mat-chip *ngIf="_currentBuitCriteria" (removed)="clearCurrent()" class="py-2">
                {{labelFromName(_currentBuitCriteria.subject)}} {{_currentBuitCriteria.predicate}}
                <button matChipRemove [attr.aria-label]="'remove ' + crit" class="py-0 text-xs text-black">
                    <mat-icon svgIcon="heroicons_outline:x" class="icon-smaller" [inline]="true"></mat-icon>
                </button>
            </mat-chip>
        </mat-chip-list>
        <input
            matInput
            placeholder="{{placeHolderContent()}}"
            class="py-0 my-0"
            #filterInput
            [formControlName]="'filterSearch'"
            [matAutocomplete]="auto"
            [matAutocompleteDisabled]="!shouldDisplayAutoComplete()"
            [matChipInputFor]="filterList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="manageInputEnd($event)"
            />
        <input [matDatepicker]="picker" [formControlName]="'pickerInput'" class="invisible">
        <mat-datepicker #picker class="test"></mat-datepicker>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="manageSelected($event)">
            <ng-container *ngIf="isSubjectMode()">
                <mat-option *ngFor="let column of notAlreadyFilteringColumnDefinitions()" [value]="column.name">
                {{column.title}}
                </mat-option>
            </ng-container>
            <ng-container *ngIf="isObjectModeWithEnumColumn()">
                <mat-option *ngFor="let column of objectEnumValues()" [value]="column.value">
                {{column.title}}
                </mat-option>
            </ng-container>
        </mat-autocomplete>
    </mat-form-field>
</form>
