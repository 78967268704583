import { APP_INITIALIZER, LOCALE_ID, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { AnapayaModule } from './anapaya/anapaya.module';
import { AnapayaNavigationService } from './anapaya/service/navigation.service';
import { UserService as AnapayaUserService } from './anapaya/service/user.service';
import { NavigationService } from './core/navigation/navigation.service';
import { UserService } from './core/user/user.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { OryInterceptor } from './anapaya/interceptor/ory.interceptor';
import { environment } from 'environments/environment';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { DealService } from './anapaya/agx/service/deal.service';
import { AbstractNotifier } from './anapaya/service/abstract-notifier.service';
import { NotificationService } from './anapaya/service/notification.service';
import { PriceListRequestService } from './anapaya/agx/service/price-list-request.service';
import { OrderService } from './anapaya/agx/service/order.service';
import { ContractService } from './anapaya/service/contract.service';

const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    //enableTracing: true
};

// services that can notify must be added to the following list
// and implement AbstractNotifer
const notifiers = [
    ContractService,
    DealService,
    PriceListRequestService,
    OrderService
];


let providers: Provider[] = [
    DatePipe,
    DecimalPipe,
    {provide: NavigationService, useExisting: AnapayaNavigationService, multi: false},
    {provide: UserService, useExisting: AnapayaUserService },
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    {
        provide: APP_INITIALIZER, useFactory: ( notificationService: NotificationService, ...services: AbstractNotifier[] ) => {
            return () => {
                notificationService.registerAll(services);
            }
        }, 
        deps: [NotificationService, ...notifiers],
        multi: true
    }
   // {provide: LOCALE_ID, useValue: 'fr_CH'},
];
if(!environment.production){
    providers.push({provide: HTTP_INTERCEPTORS, useClass: OryInterceptor, multi: true});
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        MatNativeDateModule,
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        //FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,
        // 3rd party modules that require global configuration via forRoot
        //MarkdownModule.forRoot({
        //}),

        //
        AnapayaModule,
    ],
    providers: providers,
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
