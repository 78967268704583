<mat-drawer-container (backdropClick)="closeAndBack()" class="w-full bg-card dark:bg-transparent">
    <mat-drawer-content>
        <div class="pb-12">
            <div class="items-start justify-between py-8 px-8">
                <div class="text-4xl font-extrabold tracking-tight leading-none">Members</div>
                <div class="ml-0.5 font-medium text-secondary">
                    {{_length}} member<ng-container *ngIf="_length > 1">s</ng-container>
                </div>
                <div class="w-full flex gap-4 items-center py-5">
                    <form [formGroup]="searchForm" class="w-full">
                        <anapaya-simple-search [formControlName]="'filterSearch'" class="w-3/4" [placeholder]="placeholder"></anapaya-simple-search>
                    </form>
                    <button mat-flat-button="" (click)="openCreate()" class="px-6 ml-3 mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base" *ngIf="canWrite()">                        
                        <mat-icon svgIcon="heroicons_outline:plus"></mat-icon>
                        <span class="ml-2 mr-1">Add</span> 
                    </button>
                </div>
            </div>
            <table mat-table [dataSource]="contacts$" class="w-full" matSort (matSortChange)="sortData($event)">
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>
                        ID
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.id}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="firstName">
                        First name
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.firstName}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef class="max-w-50" mat-sort-header="lastName">
                        Last name
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.lastName}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="admin">
                    <th mat-header-cell *matHeaderCellDef class="w-20">
                        
                    </th>
                    <td mat-cell *matCellDef="let element" >
                        <ng-container *ngIf="element.permission.admin">
                            <span class="inline-bloc rounded-full px-2.5 py-1 h-7 text-sm w-40 bg-warn-400" >
                                Admin
                            </span>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>
                        Actions
                    </th>
                    <td mat-cell *matCellDef="let element" class="p-2"> 
                        <button mat-mini-fab color="basic" (click)="deleteContact(element.id)" *ngIf="canWrite()" [disabled]="isSelf(element.id)"><mat-icon svgIcon="heroicons_outline:trash"></mat-icon></button>
                        <button mat-mini-fab color="basic" class="ml-2" (click)="openEdit(element.id)" *ngIf="canWrite()"><mat-icon svgIcon="heroicons_outline:pencil"></mat-icon></button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns" class=""></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="bg-card hover:bg-gray-100 dark:transparent dark:hover:bg-hover"></tr>
            </table> 
            <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                showFirstLastButtons
                class="dark:bg-default-50"
                [pageSize]="_pageSize"
                [pageIndex]="_pageIndex"
                (page)="pageEvent($event)"
                [length]="_length"
                aria-label="Select page">
            </mat-paginator>
        </div>
    </mat-drawer-content>
    <mat-drawer #drawer
        [mode]="'over'"
        [position]="'end'"
        (keydown.escape)="closeAndBack()"
        class="w-full sm:w-140 md:w-160 xl:w-192 xxl:w-200 dark:bg-gray-700 bg-accent-100"
        >
        <div class="p-4" class="w-full sm:w-140 md:w-160 xl:w-192 xxl:w-200">
            <div class="relative h-20 sm:h-20 px-8 sm:px-12">
                <div class="flex items-center justify-end sm:justify-start w-full max-w-3xl mx-auto pt-6">
                    <button 
                        mat-icon-button="" 
                        class="mat-mdc-tooltip-trigger mdc-icon-button mat-mdc-icon-button mat-unthemed mat-mdc-button-base tex-primary"
                        
                        (click)="closeAndBack()"
                        >
                        <span class="mat-button-wrapper" class="" >
                            <mat-icon svgIcon="heroicons_outline:x"></mat-icon>
                        </span>
                    </button>
                </div>
            </div>
            <div>
                <router-outlet name="contact"></router-outlet>
            </div>
        </div>
    </mat-drawer>
</mat-drawer-container>