import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { upperCase } from 'lodash';
import { debounceTime, map } from 'rxjs';
import { Company } from '../model/company.model';
import { CompanyService } from '../service/company.service';

@Component({
  selector: 'app-company-chooser',
  templateUrl: './company-chooser.component.html',
  styleUrls: ['./company-chooser.component.scss']
})
export class CompanyChooserComponent implements OnInit {
  displayedColumns = ["id", "name"];
  companies: Company[];
  _length;
  placeholder="Search company";
  searchForm: FormGroup;
  currentSearch = "";
  @ViewChild(MatTable) protected table: MatTable<any>;
  currentSort: undefined|{[key: string]: "asc"|"desc"}= undefined;

  constructor(
    protected route: ActivatedRoute,
    protected companyService: CompanyService
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe( data => {
      this.companies = data.companies
      this._length = this.companies?.length;
      this.searchForm = new FormGroup({
        filterSearch: new FormControl(undefined)
      });
      this.searchForm.controls.filterSearch.valueChanges
      .pipe(
        debounceTime(300),
        map(str => {
          this.currentSearch = str;
          this._refresh();
        })
      )
      .subscribe();
    });
  }

  _refresh() {
    let regex = undefined
    if(this.currentSearch && this.currentSearch.length >= 1) {
      regex = new RegExp(this.currentSearch, "i");
    } 
    this.companyService.companies.pipe(
      map( companies => {
        let companyListTmp = companies
        if (regex) {
          companyListTmp = companies.filter(
            company => company.name.match(regex)
          )
          ;
        }

        if(this.currentSort) {
          companyListTmp = companyListTmp.sort(
            (a, b) => {
              let key = Object.keys(this.currentSort)[0];
              switch (key) {
                case 'name':
                  if (a.name > b.name) {
                    return this.currentSort[key] === "asc" ? -1 : 1;
                  } if (b.name > a.name) {
                    return this.currentSort[key] === "asc" ? 1 : -1;
                  }
                  return 0;
                default:
                  return 0;
              }
            }
          );
        }
        this.companies = companyListTmp;
        this._length = this.companies.length;
        this.table?.renderRows();

      })
    ).subscribe();
  }

  sortData(event: any) {
    if(event['direction'] === "") {
      this.currentSort = undefined;
    } else {
      this.currentSort = {};
      this.currentSort[event['active']]=event['direction'];
    }
    this._refresh();
  }
}
