import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'truncate'})
export class TruncatePipe implements PipeTransform {
  transform(value: string, length: number, symbol: string = '...'): string {
    const splitted = value.split(' ');
    let truncated = '';
    splitted.every( (part) => {
      if(truncated.length + part.length >= length) {
        return false;
      }
      truncated += ' ' + part;
      return true;
    });
    return truncated + symbol;//value.split(' ').slice(0, length).join(' ') + symbol;
  }
}
