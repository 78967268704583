import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map, Observable } from 'rxjs';
import { Permission } from '../model/permission.model';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private http: HttpClient,
    private companyService: CompanyService
  ) { }

  read(contactId: number): Observable<Permission> {
    const currentCompany = this.companyService.currentCompany;
    return this.http
      .get<{permission: Permission}>(`${environment.apiUrl}/companies/${currentCompany.id}/contacts/${contactId}/permission`, {withCredentials: true})
      .pipe(map( response => response.permission ));
  }

  getSchema(): Observable<any> {
    return this.http.get<{schema: any}>(
      `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/schemas/permission`, {withCredentials: true}).pipe( map( response => response.schema));
  }

  update(contactId: number, permission: Permission): Observable<Permission> {
    const currentCompany = this.companyService.currentCompany;
    return this.http.patch<{permission: Permission}>(
      `${environment.apiUrl}/companies/${currentCompany.id}/contacts/${contactId}/permission`, permission, {withCredentials: true}).pipe(
      map(response => response.permission)
    );
  }

  /**
   * checks a set of permissions provided as a set of path, returns a 'or' or 'and' operation result on these
   *
   * @param permission
   * @param path
   * @param mode
   * @returns
   */
  checkPermission(permission: Permission, path: {[key: string]: string[]}, mode: 'read'|'write'|'activate'|string='read'): boolean {
    let acc: boolean ;
    if (permission.admin && Object.keys(path).includes('or') && path['or'][0] === '__admin__') {
      return true;
    }
    //

    Object.keys(path).forEach((k) => {
      acc = (k === 'or') ? false : true;

      path[k].forEach( (p) => {
        let ctx: {[key: string]: any} = permission.features;

        //walking trough path
        p.split('.').forEach ( (part) => {
          if (ctx instanceof Object && Object.keys(ctx).includes(part)) {
            ctx = ctx[part];
          }
        });
        let tmpRight;
        if(typeof ctx === 'boolean') {
          tmpRight = ctx;
        }
        //checking for access mode value, processing to operation
        else if (typeof ctx === 'object' && Object.keys(ctx).includes(mode) && ctx[mode] !== undefined && ctx[mode] !== null) {
          tmpRight = ctx[mode];
        } else {
          tmpRight = false;
        }
        acc = (k === 'or') ? (acc || tmpRight) : (acc && tmpRight);
      });

    });

    return acc;
  }

  forUser(userId: number, companyId: number): Observable<Permission> {
    return this.http
    .get<{permission: Permission}>(`${environment.apiUrl}/companies/${companyId}/contacts/${userId}/permission`, {withCredentials: true})
      .pipe(map( response => response.permission )
    );
  }

  atLeastOneAccess(perms: Permission): boolean {
    let has = false;

    Object.keys(perms.features).forEach(
      (ftk) => {
        Object.keys(perms.features[ftk]).filter( k => !['read', 'write'].includes(k)).forEach(
          (k) => {
            Object.keys(perms.features[ftk][k]).forEach( (subfK) => {
              has = has ||perms.features[ftk][k][subfK].read || perms.features[ftk][k][subfK].write;
            });
          }
        );
        if(perms.features[ftk].read || perms.features[ftk].write) {
          has=has||true;
        }
      }
    );
    return has;
  }
}
