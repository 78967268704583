import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { PaneCloseService } from 'app/modules/contacts/service/pane-close.service';
import { debounceTime, map, Subscription } from 'rxjs';
import { Company } from '../model/company.model';
import { Contact } from '../model/contact.model';
import { AuthService } from '../service/auth.service';
import { CompanyService } from '../service/company.service';
import { ContactService } from '../service/contact.service';
import { PermissionService } from '../service/permission.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit, OnDestroy {
  @ViewChild(MatDrawer) drawer: MatDrawer;
  @ViewChild(MatSort) sortHeader: MatSort;
  @ViewChild(MatTable) protected table: MatTable<any>;
  displayedColumns = ['id', 'firstName', 'lastName', 'admin', 'actions'];
  drawerOpened=false;
  paneCLoseSubscription: Subscription;
  searchForm: FormGroup;
  _length;
  _pageSize=20;
  _pageIndex=0;
  _currentUser: Contact;
  columnDefinitions = [];
  placeholder='Search members';
  currentSearch = '';
  currentSort: undefined|{[key: string]: 'asc'|'desc'}= undefined;
  _company: Company;
  protected contacts$: MatTableDataSource<Contact> =new MatTableDataSource();

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected confirmationService: FuseConfirmationService,
    protected contactService: ContactService,
    protected paneClose: PaneCloseService,
    protected snackBar: MatSnackBar,
    protected permService: PermissionService,
    protected authService: AuthService,
    protected userService: UserService,
    protected companyService: CompanyService,
    protected formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe( (data) => {
      this._company = data.company;
      this._refreshList(data.contacts);
    });

    this.userService.user$.subscribe(
      (user) => {
        this._currentUser = user;
      }
    );

    this.searchForm = this.formBuilder.group({
      filterSearch: new FormControl<string>(undefined, {updateOn: 'change'})
    });

    this.searchForm.valueChanges.pipe(
      debounceTime(300),
      map((x)=> {
        this.currentSearch = x.filterSearch;
        this.contactService.list(this.currentSearch, this.currentSort, this._company?.id).subscribe ((list) => {
          this._refreshList(list);
        });
      }),
    ).subscribe();

    window.setTimeout(
      (that) => {
        if(that.route.children.length > 0) {
          this.drawer.toggle();
        }
      },
      200,
      this
    );

    this.router.events.subscribe(
      (event) => {
        if(event instanceof NavigationStart && this.drawerOpened) {
          this.drawer.close();
        }
      }
    );

    this.paneCLoseSubscription = this.paneClose.canClose.subscribe( x => this.closeAndBack());
  }

  ngOnDestroy(): void {
    this.paneCLoseSubscription.unsubscribe();
  }

  deleteContact(contactId: number): void {
    const dialogRef = this.confirmationService.open({});
    dialogRef.afterClosed().subscribe( (result) => {
      if(result === 'confirmed') {
        this.contactService.delete(contactId).subscribe(
          {
            next: (response) => {
              this.contactService.list(this.currentSearch, this.currentSort, this._company?.id).subscribe ((list) => {
                this.contacts$.data = list;
                this.table.renderRows();
                this.snackBar.open('Contact deleted', undefined, {duration: 3000});
              });
            },
            error: (err) => {}
          }
        );
      }
    });
  }

  /**
   * opens the edit drawer
   *
   * @param contactId
   */
  openEdit(contactId: number): void {

    this.router.navigate(['./', {outlets: {contact: [contactId]}}], {relativeTo: this.route}).then(
      (r) => {
        this.drawer.open();
        this.drawerOpened = true;
      }

    );
  }

  /**
   * opens the edit drawer
   *
   * @param contactId
   */
  openCreate(): void {
    this.router.navigate(['./', {outlets: {contact: 'new'}}], {relativeTo: this.route}).then(
      (r) => {
        this.drawer.open();
        this.drawerOpened = true;
      }
    );
  }

  closeAndBack(): void {
    this.drawerOpened = false;
    this.router.navigate(['./'], {relativeTo: this.route});
    this.drawer.close();
    this.contactService.list(this.currentSearch, this.currentSort, this._company?.id).subscribe ((list) => {
      this._refreshList(list);
    });
  }

  _refreshList(list: Contact[]): void {
    const _list = list;

    this._length = _list.length;

    this.contacts$.data = _list
    .slice(this._pageIndex*this._pageSize, (this._pageIndex*this._pageSize) + this._pageSize);
      this.table?.renderRows();
  }

  canWrite(): boolean {
    return this.contactService.canWrite();
  }

  pageEvent(event: PageEvent): void {
    this._pageIndex = event.pageIndex;
    this._pageSize = event.pageSize;
    this.contactService.list(this.currentSearch, this.currentSort, this._company?.id).subscribe ((list) => {
      this._refreshList(list);
    });
  }

  isSelf(contactId: number): boolean {
    return this._currentUser?.id === contactId;
  }

  sortData(event: any): void {
    if(event['direction'] === '') {
      this.sortHeader.direction = 'asc';
      this.currentSort[event['active']] = 'asc';
    } else {
      this.currentSort = {};
      this.currentSort[event['active']] = event['direction'];
    }
    this.contactService.list(this.currentSearch, this.currentSort, this._company?.id).subscribe ((list) => {
      this._refreshList(list);
    });
  }
}
