import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map, Observable, of } from 'rxjs';
import { Contact } from '../model/contact.model';
import { CompanyService } from './company.service';
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(
    private http: HttpClient,
    private companyService: CompanyService,
    private userService: UserService
  ) {}

  list(searchString?: string, sort?: {[key: string]: "asc"|"desc"}, companyId?: string): Observable<Contact[]> {
      let _companyId = companyId ?? this.companyService.currentCompany.id;

      return this.http.get<{contacts: Contact[]}>(`${environment.apiUrl}/companies/${_companyId}/contacts`, {withCredentials: true}).pipe(
        map(
          response => {
            let regex = undefined;
            if (searchString && searchString.length > 1) {
              regex = new RegExp(searchString, "i");
            }
            return response.contacts.filter(
              contact => {
                if (regex) {
                  return contact.email.match(regex) || 
                    contact.lastName.match(regex) ||
                    contact.firstName.match(regex);
                } else {
                  return true;
                }
              }
            )
            .sort( (a, b) => {
              if(sort) {
                let key = Object.keys(sort)[0]
                switch (key) {
                  case 'firstName':
                    if (a.firstName > b.firstName) {
                      return sort[key] === "asc" ? -1 : 1;
                    } if (b.lastName > a.lastName) {
                      return sort[key] === "asc" ? 1 : -1;
                    }
                    return 0;
                  case 'lastName': 
                    if (a.lastName > b.lastName) {
                      return sort[key] === "asc" ? -1 : 1;
                    } if (b.lastName > a.lastName) {
                      return sort[key] === "asc" ? 1 : -1;
                    }
                    return 0;
  
                  default:
                    return 0;
                    
                }
              }
              return 0
            })
          }
        )
      );
      
  }

  create(contact: Contact, options?: {shouldReceiveInviteEmail?: boolean}): Observable<Contact> {
    let companyId = this.companyService?.currentCompany.id;
    let contactDataWithOptions: Contact & {shouldReceiveInviteEmail?: boolean} = contact;
    if(options?.shouldReceiveInviteEmail) {
      contactDataWithOptions.shouldReceiveInviteEmail = options.shouldReceiveInviteEmail;
    }
    return this.http.post<{contact: Contact}>(`${environment.apiUrl}/companies/${companyId}/contacts/`, contactDataWithOptions, {withCredentials: true}).pipe(
      map(
        response => response.contact
      )
    );
  }

  read(contactId: string): Observable<Contact> {
    let companyId = this.companyService?.currentCompany.id;
    return this.http.get<{contact: Contact}>(`${environment.apiUrl}/companies/${companyId}/contacts/${contactId}`, {withCredentials: true}).pipe(
      map(
        response => {
          return response.contact;
        }
      )
    )
  }

  update(contactId: number, contact: Contact, options?: {shouldReceiveInviteEmail?: boolean}): Observable<Contact> {
    let companyId = this.companyService?.currentCompany.id;
    let contactDataWithOptions: Contact & {shouldReceiveInviteEmail?: boolean} = contact;
    if(options?.shouldReceiveInviteEmail) {
      contactDataWithOptions.shouldReceiveInviteEmail = options.shouldReceiveInviteEmail;
    }
    return this.http.patch<{contact: Contact}>(`${environment.apiUrl}/companies/${companyId}/contacts/${contactId}`, contactDataWithOptions, {withCredentials: true}).pipe(
      map(
        response => response.contact
      )
    );
  }

  delete(contactId: number): Observable<boolean> {
    let companyId = this.companyService?.currentCompany.id;
    return this.http.delete<{contact: Contact}>(`${environment.apiUrl}/companies/${companyId}/contacts/${contactId}`, {withCredentials: true}).pipe(
      map(
        response => true
      )
    );
  }

  canWrite(): boolean {
    return !this.companyService.currentCompany ? false : (this.companyService.currentCompany.permission.admin || this.userService.userPermissionForPath(this.companyService.currentCompany.id, "contacts", "write"))
  }
}