import { CdkPortal } from '@angular/cdk/portal';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, catchError, map, mergeMap, Observable, of, Subject, tap } from 'rxjs';
import { Company } from '../model/company.model';
import { PermissionService } from './permission.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  _currentCompany: Company|undefined = undefined;
  currentCompany$: BehaviorSubject<Company|undefined>;
  companies$: BehaviorSubject<Company[]|undefined> = new BehaviorSubject<Company[]|undefined>(undefined);
  permissionService: PermissionService;
  companyListObservable: Observable<Company[]>|undefined;

  constructor(
    private http: HttpClient,
  ) { 
    this.currentCompany$ = new BehaviorSubject(undefined) 
  }

  read(companyId: string): Observable<Company> {
    return this.http.get<{company: Company}>(`${environment.apiUrl}/companies/${companyId}`, {withCredentials: true}).pipe( 
      catchError(
        err => {
          throw err;
        }
      ),
      map(
      response => 

        response.company
      
    ));
  }

  readByName(name: string): Observable<Company> {
    return this.http.get<{companies: Company[]}>(`${environment.apiUrl}/companies`, {withCredentials: true}).pipe( map(response => {
      return response.companies[0]
    }))
  }

  suggest(name: string): Observable<{name: string}[]> {
    return of([])
  }

  post(company: Company): Observable<Company>{
    return this.http.post<{company: Company}>(`${environment.apiUrl}/companies/`, company).pipe(map( response => response.company))
  }

  list(name?: string, update=true): Observable<{companies: Company[]}> {

    return this.http.get<{companies: Company[]}>(`${environment.apiUrl}/companies`, 
      {
        withCredentials: true,
        params: name ? {'where[name]': `$ilike:${name}`, "where[isDeleted]": false} : undefined
      }
    ).pipe( 
      catchError(
        err => {
          throw err;
        }
      ),
      map(
      response => {
        //response.companies.forEach( el => {el.id = parseInt(el.id+"")});
        if(update) {
          this.companies$.next(response.companies);
        }

        return response
      }
    ));
  }

  set currentCompany(company: Company|undefined) {
    this._currentCompany = company
    this.currentCompany$.next(company);
  }

  get currentCompany(): Company|undefined {
    return this._currentCompany;
  }

  get companies(): Observable<Company[]> {
    return this.companies$.pipe(
      mergeMap( companies => {
        if(!companies ) {
          if(!this.companyListObservable) {
            this.companyListObservable = this.list().pipe(
              map(x => {

                if(x.companies.length) {
                  this.companies$.next(x.companies);
                  //this.companyListObservable = undefined;
                  return x.companies
                } else {
                  this.companies$.next(undefined);
                  //this.companyListObservable = undefined;
                  return undefined
                }
              })
            );
          }

          return this.companyListObservable;

        } else {
          return of(companies)
        }
        


    }));
  }

  clearCompanies() {
    this.currentCompany = undefined;
    this.companies$.next(undefined);
  }
}
