import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { delay, map, Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss']
})
export class SignOutComponent implements OnInit {
  impersonation = false;
  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        let preferAdmin = false;
        let assume = params.assume;
        let signoutObservable: Observable<any>;
        let route = undefined;
        if ([true, "true"].includes(assume)) {
          route = "/admin/impersonation";
          preferAdmin = true;
          this.impersonation = true
          signoutObservable = this.auth.signOutAs();
        } else {
          signoutObservable = this.auth.signOut();
        }
        signoutObservable.pipe(
          delay(1000),
          map
          (r => {

            params = {};
            if (preferAdmin) {
              params ={prefer: route};
            }

            this.router.navigate(["/hub"], {queryParams: params})
          })
          ).subscribe()
      }
    )
  }
}
