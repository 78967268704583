import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {
  supportUrl = environment.technicalSupportDeskUrl;
  message = "Your account does not allow you to use this platform.";
  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe( map => {
      if(map.reason === "email_not_verified") {
        this.message = "Your email is not verified."
      }
    })
  }

}
