import { Company } from 'app/anapaya/model/company.model';
import { Delivery } from './delivery.model';

/* eslint-disable */
export enum DealStatus {
    OPPORTUNITY = 'opportunity',
    PROPOSAL_SENT = 'proposalSent',
    CLOSING = 'closing',
    CLOSED_WON = 'closedWon',
    CLOSED_LOST = 'closedLost',
    REQUESTED = 'requested'
}
/* eslint-enable */
export class Deal {
    public id?: string;
    public description?: string;
    public resellerCompanyId?: string;
    public resellerCompany?: Company;
    public endClientCompanyId?: string;
    public providerComany?: Company;
    public reference?: string;
    public status?: DealStatus;
    public createdAt?: Date;
    public name?: string;
    public deliveries?: Delivery[];
    public endClient?: Company;
    public quantity?: number;
    public isConfidential?: boolean;
    public comment?: string;
    public scionAs?: string;
}
