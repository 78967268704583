import { Component, OnInit } from '@angular/core';
import { ContactListComponent } from '../contact-list/contact-list.component';

@Component({
  selector: 'app-admin-contact-list',
  templateUrl: './admin-contact-list.component.html',
  styleUrls: ['./admin-contact-list.component.scss']
})
export class AdminContactListComponent extends ContactListComponent implements OnInit {
  override _company;

  override ngOnInit(): void {
    super.ngOnInit();
    if(this.route.params['company']) {
      this._company = {id: this.route.params['company']};
    }
  }

  impersonate(userId: number): void {
    //company name needs to be put in a variable beforehand because it gets cleared at impersonation time
    const currentCompanyId = this._company.id ?? this.companyService.currentCompany.id;
    this.authService.signInAs(userId).subscribe(
      (response) => {
        this.router.navigate(['/company/', currentCompanyId]);
      }
    );
  }
}
