<div class="flex-auto">   
    <div class="flex items-start justify-between py-8 px-8">
        <div class="text-4xl font-extrabold tracking-tight leading-none">Companies</div>
    </div>
    <table mat-table [dataSource]="companies" class="w-full" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>
                ID
            </th>
            <td mat-cell *matCellDef="let element" [routerLink]="[element.id]">{{element.id}}
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="name">
                Name
            </th>
            <td mat-cell *matCellDef="let element" [routerLink]="[element.id]" > {{element.name}}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover:bg-gray-600 dark:hover:bg-gray-700"></tr>
    </table>
</div>