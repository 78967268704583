<div class="flex item-center">
    <!-- Button -->
    <button 
        *ngIf="isIdentityAssumed()"
        class="hover:cursor-default button-warning mt-0 bg-warn-200 text-warn mr-1"
        mat-flat-button
        >
        Impersonating {{user?.firstName}} {{user?.lastName}} {{currentCompany ? '/ ' + currentCompany.name : ''}}
    </button>
    <button
        mat-icon-button
        [matMenuTriggerFor]="userActions">
        <span class="relative">
            <img
                class="w-7 h-7 rounded-full"
                *ngIf="showAvatar && user && user.avatar"
                [src]="user.avatar">
            <mat-icon
                *ngIf="!showAvatar || !user || !user.avatar"
                [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            <span
                class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
                class="mr-px mb-px"
            ></span>
        </span>
    </button>
</div>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button *ngIf="companies?.length > 0" mat-menu-item class="mat-mdc-menu-item mat-mdc-focus-indicator mdc-list-item" style="height: 100%; padding-top: 2px; padding-bottom: 8px">
        <span class="flex flex-col leading-none" *ngIf="user">
            <span class="mt-1.5 text-md font-medium">Signed in as</span>
            <span class="mt-1.5 text-md text-primary text-ellipsis"> {{user?.firstName}} {{user?.lastName}} </span>
            <span class="mt-1.5 text-md text-secondary text-ellipsis font-medium"> {{user?.email}}</span>
        </span>
    </button><mat-divider *ngIf="companies?.length > 0" class="my-2"></mat-divider>
    <div *ngIf="companies?.length > 1; then with_sub_menu else without_sub_menu"></div>
    <ng-template #with_sub_menu>
        <button mat-menu-item [matMenuTriggerFor]="chooseComp">
            <span class="flex flex-col leading-none">
                <span>Company</span>
                <span class="mt-1.5 text-md text-primary font-medium">{{company ? company.name : 'set a company'}}</span>
            </span>
        </button>
    </ng-template>
    <ng-template #without_sub_menu>
        <button *ngIf="companies?.length > 0" mat-menu-item>
            <span class="flex flex-col leading-none">
                <span>Company</span>
                <span class="mt-1.5 text-md text-primary font-medium">{{company ? company.name : 'set a company'}}</span>
            </span>
        </button>
    </ng-template>
    <mat-divider *ngIf="companies?.length > 0" class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>

<mat-menu
    class="user-status-menu"
    #chooseComp="matMenu">
    <button *ngFor="let company of companies"
        mat-menu-item
        [routerLink]="getCompanyLink(company.id)">
        <span>{{company.name}}</span>
    </button>
</mat-menu>