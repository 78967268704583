import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Configuration } from '@ory/client';
import { environment } from 'environments/environment';
import { relativeTimeRounding } from 'moment';
import { Observable, map, catchError, of, mergeMap, mapTo, Subscription } from 'rxjs';
import { Contact } from '../model/contact.model';
import { Session } from '../model/session.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  contact: Contact;
  _session: Session|undefined;
  keepSessionDuration: number = 5000;
  getSessionObservable: Observable<Session>;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private router: Router
  ) { 
    const conf = new Configuration({
      basePath: environment.kratosUrl,
      baseOptions: {
        withCredentials: true
      }
    });
  }

  /**
   * Logs an user in with its credentials
   * @param email 
   * @param password 
   * @returns 
   */
  signIn(email: string, password: string): Observable<any> {
    
    return this.initializeSelfServiceLoginFlowForBrowsers().pipe(
      mergeMap(
        response => {
          let csrfToken = response.ui.nodes[0].attributes as {value: string};
          let data = {csrf_token: csrfToken.value, identifier: email, password: password, method: "password"}
          return this.submitSelfServiceLoginFlow(data, response.id).pipe( 
            catchError(
              err => {
                throw err
              }
            ),
            map(
              response => {
                this.getSession().subscribe();
                return response;
              }
            )
          )
        }
      )
    ); 
  }

  superSignOut(): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/sessions/admin`, {withCredentials: true}).pipe(
      map( r => {
        this._session = undefined
        return r
      })
    );
  }

  /**
   * Impersonation login
   * @param contactId 
   * @returns 
   */
  signInAs(contactId: number): Observable<any> {
    let route = `${environment.apiUrl}/sessions/contact`;

    return this.http.patch<{sessions: Session}>(route, {contactId}, {withCredentials: true}).pipe(map(
      response => {
        //first clearing all previous data concerning identity
        this._session = undefined
        this.userService.user = undefined;
        // then setting newly impersonated session data
        this.setUpSession(response.sessions);
        this.userService.isIdentityAssumed = true;
        return true
      }
    ));
  }

  /**
   * Logout from impersonation
   * @returns
   */
  signOutAs(): Observable<any> {
    let route = `${environment.apiUrl}/sessions/contact`;
    return this.http.patch<{sessions: Session}>(route, {contactId: null}, {withCredentials: true}).pipe(
      catchError(
        err => {

          window.sessionStorage.clear();
          this.signOut().pipe( map( s => {console.log("signout")})).subscribe();
          throw err;
        }
      ),
      map(
        response => {
          this.setUpSession(undefined);
          this._session = undefined;
          this.userService.user = undefined;
          this.userService.isIdentityAssumed = false;
        }
      )
    );
  }

  initializeSelfServiceLoginFlowForBrowsers(): Observable<any> {
    return this.http.get(`${environment.kratosUrl}/self-service/login/browser`, {withCredentials: true});
  }

  submitSelfServiceLoginFlow(data, flowId): Observable<any> {
    return this.http.post(`${environment.kratosUrl}/self-service/login?flow=${flowId}`,data, {withCredentials: true});
  }

  createSelfServiceLogoutFlowUrlForBrowsers(): Observable<any> {
    return this.http.get(`${environment.kratosUrl}/self-service/logout/browser`, {withCredentials: true});
  }

  submitSelfServiceLogoutFlow(logout_token): Observable<any> {
    return this.http.get(`${environment.kratosUrl}/self-service/logout?token=${logout_token}`, {withCredentials: true});
  }

  /**
   * Calls kratos backend to revoke session
   * @returns 
   */
  signOut(): Observable<any> {
    return this.createSelfServiceLogoutFlowUrlForBrowsers().pipe(
      map(
      (r: any) => {
        window
        return this.submitSelfServiceLogoutFlow(r.logout_token).subscribe(
          _ => {
            this._session = undefined;
            this.userService.user = undefined
          }
        )
      })
    )
  }

  /**
   * Tells if an user is logged in
   * @returns 
   */
  isSignedIn(asSuperAdmin: boolean = false, checkImpersonate=false): Observable<boolean> {
    return this.getSession().pipe(
      map (response => {

        this.userService.isIdentityAssumed = (response.originalContact !== undefined);

        return response?.contact !== null
      })
    )
  }

  setUpSession(session: Session) {
    this.userService.user = session?.contact ?? undefined;
    this.userService.isAdmin = (session?.admin !== undefined)
  }

  getSession(): Observable<Session> {
    if(this._session !== undefined) {
      return of(this._session)
    } 
    
    if (!this.getSessionObservable) {
      this.getSessionObservable = this.http.get<{sessions: Session}>(`${environment.apiUrl}/sessions`, {withCredentials: true}).pipe(
        catchError(
          err => {
            if(err.status === 403){
              let reason = undefined;
              if(err.error?.error?.code === "AUTH_EMAIL_NOT_VERIFIED") {
                reason = "email_not_verified"
              }
              this.router.navigate(["/not-authorized"], {queryParams: {reason}})
            }
            throw err;
          }
        ),
        map(
          response => {
            this.setUpSession(response.sessions)
            this.getSessionObservable = undefined
            //session is stored for a limited time and re-queried
            window.setTimeout((that) => {that._session = undefined}, this.keepSessionDuration, this)
            this._session = response.sessions;
            return response.sessions;
          }
        )
      );
    }
    return this.getSessionObservable

  }
}
