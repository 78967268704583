import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Contact } from '../model/contact.model';
import { Permission } from '../model/permission.model';

@Component({
  selector: 'app-user',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit {

  userForm: FormGroup = new FormGroup({
    permissions: new FormArray([])
  });
  permFormArray: FormArray = new FormArray([]);
  contact: Contact;

  permissions: Permission[] = [{
    id: "foo",
    companyId : 43,
    contactId: 324,
    admin: false,
    features: {
      feature_1: {
        read: true,
        write: true,
        subFeat: {
          read: true,
          write: false,
        },
        subFeat2: {
          read: false,
          write: true
        }
      },
      feature_2: {
        read: true,
        write: false,
        subft1: {
          read: true,
          write: false
        }
      }
    }
  }];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) { 
  
  }

  ngOnInit(): void {
    let formControlArr = [];
    this.route.data.subscribe( p => {

      this.contact = p.session.contact;
      
      //this.contact.permissions.forEach( perm => {
      formControlArr.push(new FormControl(this.contact.permission));
      //})
  
      this.permFormArray = this.formBuilder.array(formControlArr);
      this.userForm = this.formBuilder.group({
        firstName: new FormControl(this.contact.firstName),
        lastName: new FormControl(this.contact.lastName),
        email: new FormControl(this.contact.email),
        permissions: this.permFormArray
      })
    });
  }

  validate() {

  }

}
