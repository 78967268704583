import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertType } from '@fuse/components/alert';
import { SelfServiceRecoveryFlowState } from '@ory/kratos-client';
import { environment } from 'environments/environment';
import { catchError, map } from 'rxjs';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class SignInComponent {

  signInForm: FormGroup;
  showAlert = false;
  alert: { type: FuseAlertType; message: string } = {
    type   : 'success',
    message: ''
  };

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
    //private auth: Aut  
  ) { }

  ngOnInit(): void {
    document.location.href=`${environment.kratosUrl}/${environment.kratosLoginPath}/?return_to=${environment.frontUrl}`
    this.signInForm = this.formBuilder.group({
      "email": new FormControl<string>('', [Validators.required]),
      "password": new FormControl<string>('', [Validators.required]),
      "rememberMe": new FormControl<boolean>(false)

    })
  }

  signIn() {
    this.showAlert = false;
    if (this.signInForm.valid) {
      let email = this.signInForm.value.email;
      let pwd = this.signInForm.value.password;
      this.auth.signIn(email, pwd).pipe(
        catchError(
          err => {
            // Set the alert
            this.alert = {
              type   : 'error',
              message: 'Wrong email or password'
          };
          
          this.signInForm.reset();
          // Show the alert
          this.showAlert = true;
          throw err;
        }
        ),
        map(
          res => {
            const redirectURL = this.activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/signed-in-redirect';
            // Navigate to the redirect url
            window.setTimeout( (self) => self.router.navigateByUrl(redirectURL), 1000, this );
            this.router.navigateByUrl(redirectURL);
          }
        ),
      ).subscribe();
    }
  }

}
