import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { UserSetting } from '../model/user-setting.model';
import { HttpClient } from '@angular/common/http';
import { CompanyService } from 'app/anapaya/service/company.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserSettingService {

  settings: {[key: string]: UserSetting};
  settingObservable: {[key: string]: Observable<UserSetting>};
  settingsLocks: {[key: string]: boolean};
  constructor(
    private http: HttpClient,
    private companyService: CompanyService,

  ) {
    this.settings = {};
    this.settingObservable = {};
    this.settingsLocks = {};
  }

  list(userId: string): Observable<UserSetting[]> {
    return this.http.get<{settings: UserSetting[]}>(`${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/contacts/${userId}/settings`,
    {withCredentials: true}).pipe(
      map(
        response => response.settings
      )
    );
  }

  update(userId: string, name: string, value: {[key: string]: any}): Observable<UserSetting> {
    return this.http.patch<{setting: UserSetting}>(
      `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/contacts/${userId}/settings/${name}`, {content: value}, {withCredentials: true})
      .pipe(map(
        response => response.setting
      ));
  }

  clear(name: string): void {
    this.settings[name] = undefined;
  }

  read(userId: string, name: string): Observable<UserSetting> {
    if(this.settings[name]) {
      return of(this.settings[name]);
    }
    if(!Object.keys(this.settingsLocks).includes(name) || this.settingsLocks[name] === false ) {
      this.settingsLocks[name] = true;
      const httpGet = this.http.get<{setting: UserSetting}>(
        `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/contacts/${userId}/settings/${name}`, {withCredentials: true});
      this.settingObservable[name] = httpGet
      .pipe(
        map(
        (response) => {
          this.settings[name] = response.setting;
          this.settingsLocks[name] = false;
          this.settingObservable[name] = undefined;
          return response.setting;
        }
      ));
    }

    return this.settingObservable[name];

  }
}
