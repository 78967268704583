import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'anapaya-simple-search',
  templateUrl: './simple-search.component.html',
  styleUrls: ['./simple-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: SimpleSearchComponent
    }
  ]
})
export class SimpleSearchComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder;
  onChange = (criterias) => {};
  onTouched = () => {};
  search: string;
  filterForm: FormGroup;

  constructor() { }

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      filterSearch: new FormControl()
    })

    this.filterForm.valueChanges.subscribe(
      value => {
        this.onChange(value.filterSearch);
      }

    )
  }

  writeValue(obj: any): void {
    this.search = obj
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
