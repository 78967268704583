// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    kratosUrl: 'https://id.dev.dnconsulting.ch',
    apiUrl: 'https://ssp-api.dev.dnconsulting.ch/api',
    frontUrl: 'https://ssp.dev.dnconsulting.ch',
    kratosLoginPath: 'auth/login',
    ticketsIncidentCategoriesMapping: {
        'EDGE_CONFIGURATION_FORM': 'edge_configuration_form',
        'CONFIGURATION_CHANGE': 'configuration_change',
        'NETWORK_ISSUE': 'network_issue',
        'SECURITY_ISSUE': 'security_issue',
        'PERFORMANCE_ISSUE': 'performance_issue',
        'GENERAL_DEFECT': 'general_defect',
        'FIELD_SERVICE': 'field_service',
        'RELEASE_UPDATE': 'release_update',
        'PORTAL_ISSUE': 'portal_issue'
    },
    technicalSupportDeskUrl: 'https://forms.monday.com/forms/584eee6076b85a828d868c5d189fec17?r=use1',
    hubspotOpportunityStageId: '61089600',
    maxPostSize: 10240,
    closedWonDealStageId : "closedwon",
    closedLostDealStageId: "closedlost"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
