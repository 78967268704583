import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyService } from './company.service';
import { UserService } from './user.service';
import { AbstractService } from './abstract.service';
import { Deal } from '../order/model/deal.model';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from 'environments/environment';
import { Contract } from '../order/model/contract.model';
import { AbstractNotifier } from './abstract-notifier.service';
import { NotificationService } from './notification.service';
import { LicenseRequestFile } from '../order/model/license-request-file.model';


@Injectable({
    providedIn: 'root'
})
export class ContractService implements AbstractService<Contract>, AbstractNotifier {
  activables: Contract[] = undefined;
  public notificationLabels: string[] = ['order.selfStartedConfirmed'];
  constructor(
      private http: HttpClient,
      private companyService: CompanyService,
      private userService: UserService,
      public notificationService: NotificationService
  ) {
  }

    read(dealId: string): Observable<Contract> {
    const params={};
    params['where[id]']=dealId;
    return this.http.get<{contracts: Contract[]; count: number}>(`${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/contracts`, {
        withCredentials: true,
        params: params
    }).pipe(
      catchError(
        (err) => {
          throw err;
        }
      ),
      map(
        response =>
          response.contracts[0]
      )
    );
  }

  list(search?: string, sort?: {[key: string]: 'asc'|'desc'}, offset: number=0, limit: number=20): Observable<{items: Contract[]; count: number}> {
    return this.http.get<{contracts: Contract[]; count: number}>(
      `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/contracts`, {withCredentials: true}
    ).pipe(
      catchError(
        (err) => {
          throw err;
        }
      ),
      map(
        response => ({items: response.contracts, count: response.count})));
  }

  clearActivables(): void {
    this.activables = undefined;
  }

  listActivable(
    search?: string,
    showActivated=false,
    sort?: {[key: string]: 'asc'|'desc'},
    offset: number=0,
    limit: number=20,
    refresh: boolean=false
  ): Observable<{items: Contract[]; count: number}> {
    const params={};
    if(refresh) {
      this.activables = undefined;
    }
    if(!showActivated) {
      params['where[effectiveDate]']='$null:true';
    }
    // if(offset) {
    //   params['offset']=offset;
    // }
    // if(limit) {
    //   params['limit']=limit;
    // }
    // if(sort) {
    //   Object.keys(sort).forEach( (key) => {
    //     params[`order[${key}]`] = sort[key];
    //   });
    // }
    return (this.activables ? of(this.activables) :
    this.http.get<{contracts: Contract[]; count: number}>(`${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/contracts/self-started`, {
        withCredentials: true,
        params: params
    }).pipe(map((x) => { this.activables = x.contracts; return x.contracts;})) ).pipe(
        catchError(
          (err) => {
            throw err;
          }
        ),
        map(
          (response) => {

            if(search) {
              response = response.filter( (item) => {
                const rgx = new RegExp(search, 'i');
                return item.number.match(rgx) || item.description.match(rgx) || item.order?.number.match(rgx) || item.order?.projectName.match(rgx);
              });
            }
            if(sort) {
            const col = Object.keys(sort)[0];
            const dir = sort[col];
            response.sort( (a, b) => {

              switch (col) {
                case 'number':
                  return this._order(a.number, b.number, dir === 'asc' ? 'asc' : 'desc');
                case 'description':
                  return this._order(a.description, b.description, dir === 'asc' ? 'asc' : 'desc');
                case 'order.number':
                  return this._order(a.order.number.toLowerCase(), b.order.number.toLowerCase(), dir === 'asc' ? 'asc' : 'desc');
                case 'endClient':
                  return this._order(a.endClientCompany.name?.toLowerCase(), b.endClientCompany.name?.toLowerCase(), dir === 'asc' ? 'asc' : 'desc');
                case 'projectName':
                  return this._order(a.order.projectName.toLowerCase(), b.order.projectName?.toLowerCase(), dir === 'asc' ? 'asc' : 'desc');
                case 'activatedBy':
                  return this._order(
                    a.startedByContact?.lastName.toLowerCase() ?? undefined,
                    b.startedByContact?.lastName.toLowerCase() ?? undefined,
                    dir === 'asc' ? 'asc' : 'desc'
                    );
                case 'effectiveDate':
                  return this._order(
                    a.effectiveDate instanceof Date ? a.effectiveDate.toISOString() : a.effectiveDate,
                    b.effectiveDate instanceof Date ? b.effectiveDate.toISOString() : b.effectiveDate,
                    dir === 'asc' ? 'asc' : 'desc'
                    );
                case 'sku':
                  return this._order(
                    a.order.sku.title,
                    b.order.sku.title,
                    dir === 'asc' ? 'asc' : 'desc'
                  );
                default:
                  return this._order(a, b, dir === 'asc' ? 'asc' : 'desc');
              }

            });
          }
          //sorting
          //slicing
          const size = response.length;
          if(!isNaN(offset) && !isNaN(limit)) {
            response = response.slice(offset, offset+ limit);
          }

          return {items: response, count: size};
        }
      ));
  }

  _order(a: any, b: any, dir: 'asc'|'desc'): number {
    if(a === undefined || a === null || a === '') {
      return 1;
    }
    if(b === undefined || b === null || b === '') {
      return -1;
    }
    if (dir === 'asc') {
      return a < b ? -1 : 1;
    } else {
      return a > b ? -1 : 1;
    }
  }

  post(deal: Deal): Observable<Contract> {
    return this.http.post<{resellerDeal: Contract}>(`${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/contracts/`, deal, {withCredentials: true}).pipe(
      map ( response => response.resellerDeal)
    );
  }

  patch(dealId: string, contract: Contract): Observable<Contract> {
    return this.http.patch<{resellerDeal: Contract}>(
      `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/contracts/${dealId}`, contract, {withCredentials: true}).pipe(
      map( (response) =>{this.refresh('order.selfStartedConfirmed');return response.resellerDeal;})
    );
  }

  licenceRequest(contractId: string, licenseRequestFile: LicenseRequestFile, contractItemId: string): Observable<{license: string}> {
    return this.http.post<{license: string}>(
      `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/contracts/${contractId}/license-requests`,
      {contractItemId, licenseRequestFile},
      {withCredentials: true}
    ).pipe(
      map(
        r => r
      )
    );
  }

  licenceRemovalRequests(contractId: string, contractItemId: string): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/contracts/${contractId}/license-removal-requests`, {contractItemId}, {withCredentials: true}
    );
  }

  delete(contractId: string): Observable<boolean> {
    return this.http.delete<any>(`${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/contracts/${contractId}`, {withCredentials: true}).pipe(
      map( response => true)
    );
  }

  canWrite(): boolean {
    return !this.companyService.currentCompany ? false : ( this.userService.userPermissionForPath(this.companyService.currentCompany.id, 'contracts', 'write'));
  }

  canActivate(): boolean {
    return !this.companyService.currentCompany ? false : ( this.userService.userPermissionForPath(this.companyService.currentCompany.id, 'contracts', 'activate'));
  }

  notify(label: string, value: string[]): void {
    this.notificationService.notify(this, label, value);
  }

  refresh(label?: string): void {
    if(label === 'order.selfStartedConfirmed') {
      this.listActivable().subscribe( response => this.notify(label, response.items.map(item => item.id)));
    }
  }
}
