import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { PaneCloseService } from 'app/modules/contacts/service/pane-close.service';
import { Contact } from '../model/contact.model';
import { Permission } from '../model/permission.model';
import { DisplayTemplate } from '../model/display-template.model';
import { ContactService } from '../service/contact.service';
import { PermissionService } from '../service/permission.service';
import { environment } from 'environments/environment';
import { CompanyService } from '../service/company.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { UserService } from '../service/user.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss']
})
export class ContactEditComponent implements OnInit {
  contact: Contact;
  permissions: Permission;
  displayPermissions= true;
  permTemplate: {[key: string]: any};
  masterPermDisplayTemplate: DisplayTemplate[] = [
    {
      label: 'Master features',
      isShown: true,
      fields: [
        {
          isShown: true,
          label: 'Contact impersonation',
          permissionField: 'master.contacts',
          fields: ['impersonate'],
          isReadWrite: false
        },
        {
          isShown: true,
          label: 'Forms access',
          permissionField: 'master.forms',
          fields: ['write'],
          isReadWrite: false
        },
        {
          isShown: true,
          label: 'Service inventory',
          permissionField: 'master.contracts',
          fields: ['read'],
          isReadWrite: false
        },
        {
          isShown: true,
          label: 'Settings',
          permissionField: 'master.settings',
          fields: ['write'],
          isReadWrite: false
        },
        {
          isShown: true,
          label: 'Approve/reject requested term',
          permissionField: 'master.resellerDeals',
          fields: ['approveFixedPrice'],
          isReadWrite: false
        }
      ]
    }
  ];
  permDisplayTemplate: DisplayTemplate[] = [
    {
      label: 'Initial configuration',
      isShown: true,
      fields: [
        {
          isShown: true,
          label: 'EDGE - configuration form',
          permissionField: 'tickets.incidentCategories.edge_configuration_form',
          isReadWrite: true
        },
        {
          isShown: true,
          label: 'EDGE - field services',
          permissionField: 'tickets.incidentCategories.field_service',
          isReadWrite: true
        }
      ]
    },
    {
      label: 'Service assurance',
      isShown: true,
      fields: [
        {
          isShown: true,
          label: 'Incident management',
          //permissionField: 'tickets',
        },
        {
          isShown: true,
          label: 'General defect',
          permissionField: `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['GENERAL_DEFECT']}`,
          isReadWrite: true,
          indent: 1
        },
        {
          isShown: true,
          label: 'Network issue',
          permissionField: `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['NETWORK_ISSUE']}`,
          isReadWrite: true,
          indent: 1
        },
        {
          isShown: true,
          label: 'Performance issue',
          permissionField: `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['PERFORMANCE_ISSUE']}`,
          isReadWrite: true,
          indent: 1
        },
        {
          isShown: true,
          label: 'Security issue',
          permissionField: `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['SECURITY_ISSUE']}`,
          isReadWrite: true,
          indent: 1
        },
        {
          isShown: true,
          label: 'Configuration change request',
          permissionField: `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['CONFIGURATION_CHANGE']}`,
          isReadWrite: true,
        },
        {
          isShown: true,
          label: 'Release updates',
          permissionField: `tickets.incidentCategories.${environment.ticketsIncidentCategoriesMapping['RELEASE_UPDATE']}`,
          isReadWrite: true,
        }
      ]
    },
    {
      label: 'Service inventory',
      isShown: true,
      fields: [
        {
          isShown: true,
          label: 'Read without price',
          permissionField: 'contracts',
          fields: ['readWithoutPrices'],
          isReadWrite: false
        },
        {
          isShown: true,
          label: 'Read with price',
          permissionField: 'contracts',
          fields: ['readWithPrices'],
          requires: ['contracts', 'readWithoutPrices'],
          isReadWrite: false
        },
        {
          isShown: true,
          label: 'Edit ICs',
          permissionField: 'contracts',
          fields: [ 'write'],
          pushRight: true,
          isReadWrite: false
        }
      ]
    },
    {
      label: 'License ordering',
      isShown: true,
      fields: [
        {
          isShown: true,
          label: 'Orders',
          permissionField: 'orders',
          isReadWrite: true
        },
        {
          isShown: true,
          label: 'Activate licenses',

          permissionField: 'contracts',
          //labels: ['Write', 'Activate'],
          fields: ['activate'],
          pushRight: true,
          isReadWrite: false

        },
      ]
    },
    {
      label: 'GATE',
      isShown: true,
      fields: [
        {
          isShown: true,
          label: 'End clients',
          permissionField: 'endClients',
          isReadWrite: true
        },
        {
          isShown: true,
          label: 'Deals',
          permissionField: 'resellerDeals',
          isReadWrite: true
        },
        // {
        //   isShown: true,
        //   label: 'Fixed price approval',
        //   permissionField: 'resellerDealOrders',
        //   fields: ['approveFixedPrice'],
        //   pushRight: true,
        //   isReadWrite: false
        // },
        {
          isShown: true,
          label: 'Price lists',
          permissionField: 'providerPriceLists',
          isReadWrite: true
        },
        {
          isShown: true,
          label: 'Deliveries',
          permissionField: 'resellerDealOrders',
          //labels: ['read', 'write'],
          //fields: ['read', 'write'],
          //isReadWrite: false,
          isReadWrite: true
        },
        {
          isShown: true,
          label: 'Send orders',
          fields: ['send'],
          permissionField: 'resellerDealOrders',
          isReadWrite: false,
        },
        {
          isShown: true,
          label: 'Acknowledge orders',
          fields: ['acknowledge'],
          permissionField: 'resellerDealOrders',
          isReadWrite: false,
        },
        {
          isShown: true,
          label: 'Configure orders',
          fields: ['configure'],
          permissionField: 'resellerDealOrders',
          isReadWrite: false,
        }, {
          isShown: true,
          label: 'Deliver orders',
          fields: ['deliver'],
          permissionField: 'resellerDealOrders',
          isReadWrite: false,
        },
        {
          isShown: true,
          label: 'Manage incoming price list request',
          isReadWrite: false,
          permissionField: 'providerPriceListRequests',
          fields: ['manageIncoming']
        },
        {
          isShown: true,
          label: 'Manage outgoing price list request',
          isReadWrite: false,
          permissionField: 'providerPriceListRequests',
          fields: ['manageOutgoing']
        },
        {
          isShown: true,
          label: 'Requested terms',
          permissionField: 'resellerDealOrders',
          pushRight: true,
          fields: ['approveFixedPrice'],
          isReadWrite: false
        },
        {
          isShown: true,
          label: 'Reseller reports',
          permissionField: 'reseller.reports',
          pushRight: false,
          fields: ['read'],
          isReadWrite: false
        },
        {
          isShown: true,
          label: 'Provider reports',
          permissionField: 'provider.reports',
          pushRight: false,
          fields: ['read'],
          isReadWrite: false
        },
        {
          isShown: true,
          label: 'Order GATE licenses',
          permissionField: 'gateOrders',
          //labels: ['Write', 'Activate'],
          fields: ['write'],
          pushRight: true,
          isReadWrite: false
        }
      ]
    },
    {
      label: 'Settings',
      isShown: true,
      fields: [
        {
          isShown: true,
          label: 'Settings',
          permissionField: 'settings',
          //labels: ['Write'],
          pushRight: true,
          fields: ['write'],
          isReadWrite: false
        }
      ]
    },
    {
      label: 'Deal registration',
      isShown: true,
      fields: [
        {
          isShown: true,
          label: 'Deals',
          permissionField: 'registeredDeals',
          isReadWrite: true
        }
      ]
    },
    {
      label: 'Files',
      isShown: true,
      fields: [
        {
          isShown: true,
          label: 'Files',
          permissionField: 'files',
          pushRight: false,
          fields: ['read'],
          isReadWrite: false
        }
      ]
    }
];

  userForm: FormGroup = new FormGroup({
    firstName: new FormControl(),
    lastName: new FormControl(),
    email: new FormControl(),
    permissions: new FormControl<Permission|undefined>(undefined),
    shouldReceiveInviteEmail: new FormControl<boolean>(false)
  });
  permFormArray: FormArray = new FormArray([]);

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private userService: UserService,
    private permissionService: PermissionService,
    private paneClose: PaneCloseService,
    private snackBar: MatSnackBar,
    private companyService: CompanyService,
    protected confirmationService: FuseConfirmationService,
  ) { }

  ngOnInit(): void {
    const formControlArr = [];
    this.route.data.subscribe( (data) => {

      this.contact = data.contact ?? undefined;
      const template = data.permissionTemplate;

      this.permTemplate = this.formFromTemplate(template) as {[key: string]: any};

      this.permissions = this._blankPermissions(template, data.permissions ? data.permissions['features']: undefined);

      this.permFormArray = this.formBuilder.array(formControlArr);

      const group = {
        firstName: new FormControl(this.contact?.firstName, Validators.required),
        lastName: new FormControl(this.contact?.lastName, Validators.required),
        email: new FormControl(this.contact?.email, [Validators.email, Validators.required]),
        shouldReceiveInviteEmail: new FormControl<boolean>(this.contact ? false :true)
      };

      group['permissions'] = new FormControl(this.permissions?.features ?? undefined);

      this.userForm = this.formBuilder.group(group);
    });
  }

  _isCurrentUser(): void {
    this.userService.user$.subscribe( (u) => {
      this.displayPermissions = !this.contact?.id || (u.id !== this.contact?.id);
    });
  }

  /**
   *
   * build a feature set from
   *
   * @param block
   * @param previous
   * @returns
   */
  _featuresFromTpl(block: {[key: string]: any}, previous?: {[key: string]: any}) {

    if(block['type'] === 'object' || Object.keys(block).includes('properties')) {
      const obj = {};

      Object.keys(block['properties']).forEach((propk) => {
        obj[propk] = this._featuresFromTpl(block['properties'][propk], previous && Object.keys(previous).includes(propk) ? previous[propk] : undefined);
      });

      return obj;
    }else if(block['type'] === 'string') {
      return '';
    } else if (block['type'] === 'boolean') {

      return (typeof previous === 'boolean') ? previous : block['default'];
    }
    return false;
  }

  _blankPermissions(tpl, previous): Permission {

    const props = this._featuresFromTpl(tpl['properties']['features'], previous);
    return {features: props};
  }

  formFromTemplate(block: {[key: string]: any}) {
    if(block['type'] === 'object' || Object.keys(block).includes('properties')) {
      const obj = {'title': block['title']};
        Object.keys(block['properties']).forEach((propk) => {

          obj[propk] = this.formFromTemplate(block['properties'][propk]);
        });

      return obj;
      }else if(block['type'] === 'string') {
          return '';
      } else if (block['type'] === 'boolean') {

          return block['readOnly'] ? {readOnly: true, value: block['default']} : {value: block['default']};
      }
    return undefined;
  }

  cancel(): void {
    const dialogRef = this.confirmationService.open({
      'title': 'Discard Modifications',
      'message': 'Are you sure you want to discard your modifications?',
      'actions': {
        'confirm': {
          'show': true,
          'label': 'Discard',
          'color': 'warn'
        },
        'cancel': {
          'show': true,
          'label': 'Cancel'
        }
      }
    });
    dialogRef.afterClosed().subscribe( (result) => {
      if(result === 'confirmed') {
        this.paneClose.canClose.next({status: 'cancel'});
      }
    });
  }

  isAdmin(): boolean {
    return !this.permissions ? false : this.permissions.admin;
  }

  isMaster(): boolean {
    return this.companyService.currentCompany.isMasterCompany;
  }

  /**
   *
   */
  validate(): void {
    if (this.userForm.valid) {
      const contact: Contact = {
        email: this.userForm.value.email,
        firstName: this.userForm.value.firstName,
        lastName: this.userForm.value.lastName
      };
      const shouldReceiveInviteEmail = this.userForm.value.shouldReceiveInviteEmail;

      if(this.contact?.id) {
        this.contactService.update(this.contact.id, contact, {shouldReceiveInviteEmail}).subscribe({
          next: (response) => {
            if(this.displayPermissions) {
              this._updatePermissions();
            }
          },
          error: (err)=> {
            if(err.error.error.code === 'MULTIPLE_ASSOCIATIONS') {
              this.snackBar.open('Impossible to change data for a member of more than one company', undefined, {duration: 3000});
            } else {
              this.snackBar.open('An error occured!', undefined, {duration: 3000});
            }
            if(this.displayPermissions) {
              this._updatePermissions();
            }

          }

        });

      } else {

        this.contactService.create(contact, {shouldReceiveInviteEmail}).subscribe({
          next: (response) => {
            const contactId=response.id;
            const permissions = this.userForm.value.permissions;
            this.permissionService.update(contactId,{features: permissions}).subscribe({
              next: (permResponse) => {
                this.paneClose.canClose.next({status: 'ok'});
                this.snackBar.open('Contact created', undefined, {duration: 3000});
              },
              error: (error) => {
                this.snackBar.open('An error occured!', undefined, {duration: 3000});
              }
            });
          },
          error: (error) => {
            this.snackBar.open('An error occured!', undefined, {duration: 3000});
          }
        });
      }
    }
  }

  _updatePermissions(): void {
    const permissions = this.userForm.value.permissions;
    this.permissionService.update(this.contact.id,{features: permissions}).subscribe({
      next: (response) => {
        // if user is current user, permission are updated
        this.userService.user$.pipe(first()).subscribe(
          (currentUser) => {
            if(currentUser.id === this.contact?.id) {
              this.companyService.currentCompany.permission = response;
              // for permissions to update properly (menu refresh, etc), we re-enter current company
              this.companyService.currentCompany = this.companyService.currentCompany;
            }
          }
        );

        this.paneClose.canClose.next({status: 'ok'});
        this.snackBar.open('Contact updated', undefined, {duration: 3000});
      },
      error: (error) => {
        this.snackBar.open('An error occured!', undefined, {duration: 3000});
      }
    });
  }
}
