import { C, COMMA, ENTER, I, SPACE } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { format } from 'date-fns';
import { ColumnDefinition } from '../individual-contract/model/column-definition.model';
import { ColumnValueAndTitle } from '../individual-contract/model/column-value-and-title.model';
import { SearchOperator } from '../individual-contract/model/search-operator.enum';
;
import { SearchCriterion } from '../model/search-criterion.model';


@Component({
  selector: 'anapaya-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: SearchBarComponent
    }
  ]
})
export class SearchBarComponent implements OnInit, ControlValueAccessor {
  _autoCompleteMode: "subject"|"predicate"|"object" = "subject";
  _currentBuitCriteria: undefined|SearchCriterion = undefined;
  filterForm: FormGroup;
  @Input() availableCriteria: ColumnDefinition[];
  @Input() valueAndTitles: {[key: string]: ColumnValueAndTitle[]};
  @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>;
  @ViewChild('picker') picker: MatDatepicker<Date>;
  _criteria: SearchCriterion[];
  //inputElement: FormControl;
  onChange = (criterias) => {};
  onTouched = () => {};

  classStr = ""

  constructor(
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {

    this.filterForm = this.formBuilder.group({
      'filterSearch': new FormControl(null, {updateOn: "change"}),
      'pickerInput': new FormControl()
    });

    this.filterForm.controls.pickerInput.valueChanges.subscribe( 
      value => {
        if(value instanceof Date) {
          this._manageInput(format(value, "dd/MM/yyyy"));
        }
      }
    );
  }

  writeValue(obj: any): void {
    this._criteria = obj
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  addFilter($event) {
    this._criteria.push($event.value);
  }

  filterSelected($event) {
    this._criteria.push($event.option.value);
  }

  manageSelected($event) {
    let value = $event.option.value;
    this._manageInput(value);
  }

  manageInputEnd($event) {
    let value = $event.value;
    if (value && value !== "") {
      this._manageInput(value);
    }
  }

  get separatorKeysCodes(): number[] {
    if (this._autoCompleteMode === "predicate") {
      return [ENTER, SPACE];
    }
    return [ENTER];
  }

  _manageInput(value: string) {
    let validInput = true;
    let mode = this.updateMode();
    if(mode === "subject") {
      if(this._columnByName(value) === undefined) {
        validInput = false;
      } else {
        this._currentBuitCriteria = {subject: value}
      }
    } else if(mode === "predicate"){
      this._currentBuitCriteria.predicate = value as SearchOperator;
    } else if (mode === "object") {
      validInput = this._checkValidInput(value);
      if(validInput) {
        this._currentBuitCriteria.object = value
      }
    }

    if(validInput) {
      this.updateMode();
      this.filterForm.controls.filterSearch?.patchValue(null);
      this.filterInput.nativeElement.value=null;
    }
  }

  _checkValidInput(value: string): boolean {
    let valid = true;
    if (this._columnByName(this._currentBuitCriteria.subject).type === "date") {
      
    }
    return valid;
  }

  /**
   * adjust mode according to current mode & values that are set
   * @returns 
   */
  updateMode(): "subject"|"predicate"|"object" {
    this.picker.close();
    if (this._criteria)
    if(this._currentBuitCriteria?.subject !== undefined && this._autoCompleteMode === "subject") {
      this._autoCompleteMode = "predicate";
    } else if (
      this._currentBuitCriteria?.subject !== undefined && 
      this._currentBuitCriteria?.predicate !== undefined && 
      this._autoCompleteMode === "predicate"
    ) {
      this._autoCompleteMode = "object";
      //should picker open ?
      let crit = this._columnByName(this._currentBuitCriteria.subject);
      if(crit.type === "date") {
        this.picker.open()
      }
    } else if (
      this. _currentBuitCriteria?.subject !== undefined &&
      this._currentBuitCriteria?.predicate !== undefined && 
      this._currentBuitCriteria?.object !== undefined &&
      this._autoCompleteMode === "object"
    ) {
      this._autoCompleteMode = "subject";
      let c = this._currentBuitCriteria;
      this._criteria.push(c);
      this._currentBuitCriteria = undefined;
      this.onChange(this._criteria);
    }

    return this._autoCompleteMode;
  }

  _updateFilterSelectionSequence() {
    if(this._autoCompleteMode === "subject") {
      this._autoCompleteMode = "predicate";
    }
  }

  /**
   * filters columns list when searching
   * @returns 
   */
  notAlreadyFilteringColumnDefinitions() {
    
    return this.availableCriteria.filter(
      crit => {
        if(this.filterForm.value.filterSearch) {
          let regex = new RegExp(this.filterForm.value.filterSearch, "i");
          return crit.title.match(regex);
        }
        return true
      }
    )
  }

  removeEvent(crit) {
    this._criteria.splice(this._criteria.indexOf(crit), 1);
    this.onChange(this._criteria);
  }

  shouldDisplayAutoComplete():boolean {
    return (this._autoCompleteMode === "subject") || this.isObjectModeWithEnumColumn()
  }

  clearCurrent() {
    this._autoCompleteMode = "subject"
    this._currentBuitCriteria = undefined;
  }

  placeHolderContent() {
    if(this._autoCompleteMode === "subject") {
      return "Type to add a filter";
    }
    if(this._autoCompleteMode=== "predicate") {
      return "Filter with '>', '<', '<=' , '>=', '=', '!=' + space"
    }
    if(this._autoCompleteMode=== "object") {
      let current = this._columnByName(this._currentBuitCriteria.subject);
      
      if (current?.type === "date") {
        return "Type a date as dd/mm/yyyy or pick one";
      }
      return "Type a value"
    }
    return "Filter..."
  }

  isSubjectMode(): boolean {
    return this._autoCompleteMode === "subject";
  }

  isObjectMode(): boolean {
    return this._autoCompleteMode === "object";
  }

  isObjectModeWithEnumColumn(): boolean {
    return this.isObjectMode() && this._columnByName(this._currentBuitCriteria.subject).type === "enum";
  }

  /**
   * returns title-value object for such typed columns definitions
   * @returns 
   */
  objectEnumValues(): ColumnValueAndTitle[] {
    let col = this._columnByName(this._currentBuitCriteria.subject);
    let typed = this.filterForm.value.filterSearch;
    if (typed) {
      let regex = new RegExp(typed, "i");
      let list: ColumnValueAndTitle[];
      if(col.enumValues && col.enumValues.length) {
        list = col.enumValues;
      } else {
        list = this.valueAndTitles[col.enumName];
      }

      return list.filter (vc => 
        vc.title.match(regex)
      )

    }
    return this.valueAndTitles[col.enumName];
  }

  /**
   * finds the column definition object for a given (path) name
   * @param name 
   * @returns 
   */
  _columnByName(name: string): ColumnDefinition|undefined {
    return this.availableCriteria.find(c => c.name === name);
  }

  /**
   * returns a label from a (path-like) name for a column
   * @param name 
   * @returns 
   */
  labelFromName(name: string): string|undefined {
    let criterion = this._columnByName(name);
    return criterion?.title ?? undefined;
  }

  /**
   * returns a title for a value for title-value typed columns
   * @param subjectName 
   * @param objectName 
   * @returns 
   */
  objectLabel(subjectName: string, objectName: string|number) {
    let crit = this.availableCriteria.find(c => c.name === subjectName)
    if (crit && crit.enumName) {
      return this.valueAndTitles[crit.enumName] ? this.valueAndTitles[crit.enumName].find(c => c.value === objectName).title : "";
    } else if(crit && crit.enumValues) {
      return crit.enumValues.find(c => c.value === objectName).title;
    }
    return objectName
  }
}
