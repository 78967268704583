<form [formGroup]="permissionForm" class="w-full">

   
    <div class="max-w-200 text-l text-secondary">
        <div class="flex items-center justify-between" *ngIf="displayReadWriteHeader">
            <div class="flex-auto">
               
            </div>
            <div class="flex-initial w-30 pl-2">
                Read
            </div>
            <div class="flex-initial w-30 pl-2">
                Edit
            </div>
        </div>
    </div>

    <ng-container *ngFor="let tplItem of displayTemplate">
        <div class="max-w-200 mt-6" *ngIf="tplItem.isShown">
            <div class="flex items-center justify-between">
                <div class="flex-auto w-30">
                <span class="text-xl">
                    {{tplItem.label}}
                </span>
                <ng-container *ngFor="let permEntry of tplItem.fields">
                    <ng-container *ngIf="permEntry.isShown">
                        <ng-containter *ngIf="permEntry.labels">
                            <div class="flex items-center justify-between text-secondary">
                                <div class="flex-auto">
                
                                </div>
                                <div class="flex-initial w-30 pl-2" *ngFor="let label of permEntry.labels">
                                    {{label}}
                                </div>
                            </div>
                        </ng-containter>                    
                        <ng-containter *ngIf="permEntry.permissionField && permEntry.isReadWrite === true">
                            <div class="flex items-center justify-between pl-2 mt-6" [formGroupName]="permEntry.permissionField">
                                
                                <div class="flex-auto pl-2 w-30" [ngClass]="{'pl-8': permEntry.indent > 0}">
                                    {{permEntry.label}}
                                </div>
                                <div class="flex-initial w-30">
                                    <mat-slide-toggle [formControlName]="'read'" class="mat-mdc-slide-toggle ml-2 mat-primary"></mat-slide-toggle>
                                </div>
                                <div class="flex-initial w-30">
                                    <mat-slide-toggle *ngIf="_hasWrite(permEntry.permissionField)" [formControlName]="'write'"class="mat-mdc-slide-toggle ml-2 mat-primary"></mat-slide-toggle>
                                </div>
                            </div>
                        </ng-containter>
                        <ng-containter *ngIf="permEntry.permissionField && permEntry.isReadWrite === false">
                            <div class="flex items-center justify-between pl-2 mt-6" [formGroupName]="permEntry.permissionField">
                                <div class="flex-auto pl-2 w-30" [ngClass]="{'pl-8': permEntry.indent > 0}">
                                    {{permEntry.label}}
                                </div>
                                <div class="flex-initial" class="w-32" *ngIf="permEntry.pushRight">
                                    <!-- spacer for when a switch has to be pushed on the right -->
                                </div>
                                <div class="flex-initial" *ngFor="let fieldVerb of permEntry.fields; let i=index" [ngClass]="{'w-60': (permEntry.fields.length < 2 && !permEntry.pushRight), 'w-30': (permEntry.fields.length>=2 || permEntry.pushRight) }">
                                    <mat-slide-toggle *ngIf="fieldVerb" [formControlName]="fieldVerb" class="mat-mdc-slide-toggle  mat-primary" [ngClass]="{'ml-0': !permEntry.pushRight, 'ml-2': permEntry.pushRight}"></mat-slide-toggle>
                                </div>
                            </div>
                        </ng-containter>
                        <ng-containter *ngIf="!permEntry.permissionField">
                            <div class="flex items-center justify-between pl-2 mt-6">
                                <div class="flex-auto pl-2 w-30" [ngClass]="{'pl-8': permEntry.indent > 0}">
                                    {{permEntry.label}}
                                </div>
                                <div class="flex-initial w-60"></div>
                            </div>
                        </ng-containter>
                    </ng-container>
                </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</form>