<div class="flex-auto px-6 pt-9 pb-12 md:p-8 md:pb-12 lg:p-12">
    <form [formGroup]="userForm" class="w-200" *ngIf="userForm">
        <mat-card class="mb-4">
            <mat-card-header>
                <mat-card-title>
                    Utilisateur
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="flex flex-col">
                    <div class="flex-auto">
                        
                        <mat-form-field class="w-full mat-mdc-form-field">
                            <mat-label>
                                First name
                            </mat-label>
                            <input matInput type="text" [formControlName]="'firstName'"/>
                        </mat-form-field>
                    </div>
                    <div class="flex-auto">
                        <mat-form-field class="w-full mat-mdc-form-field">
                            <mat-label>
                                Last name
                            </mat-label>
                            <input matInput type="text" [formControlName]="'lastName'"/>
                        </mat-form-field>
                    </div>
                    <div class="flex-auto">
                        <mat-form-field class="w-full mat-mdc-form-field">
                            <mat-label>
                                Email
                            </mat-label>
                            <input matInput type="text" [formControlName]="'email'"/>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <div [formArrayName]="'permissions'" >
            <ng-container *ngFor="let control of permFormArray.controls; let p=index">
                
                <permission-edit [formControl]="control" class="my-4">
                </permission-edit>
            </ng-container>
        </div>
        <div class="flex items-center justify-end px-6 py-5">
            <button mat-button="" class="mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base">
                <span class="mdc-button__label"> Cancel </span>
            </button>
            <button (click)="validate()" mat-flat-button="" class="px-6 ml-3 mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base">
                <span class="mat-mdc-button-persistent-ripple mdc-button__ripple"></span>
                <span class="mdc-button__label"> Save </span>
                <span class="mat-mdc-focus-indicator"></span>
                <span matripple="" class="mat-ripple mat-mdc-button-ripple"></span>
                <span class="mat-mdc-button-touch-target"></span>
            </button>
        </div>

     
    </form>
</div>
