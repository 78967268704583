import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/anapaya/service/auth.service';

@Component({
  selector: 'app-signed-hub',
  template: '',
  styleUrls: ['./signed-hub.component.scss']
})
export class SignedHubComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe( params => {
      if(params.prefer && this.authService.isSignedIn(true)) {
        this.router.navigate([params.prefer])
      }
      else if (this.authService.isSignedIn()) {
        this.router.navigate(["/company"])
      } else {
        this.router.navigate(["/"])
      }
    });

  }
}