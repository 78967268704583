import { Route } from '@angular/router';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { AdminGuard } from './anapaya/guard/admin.guard';
//import { InitialDataResolver } from 'app/app.resolvers';
import { AnapayaAuthGuard } from './anapaya/guard/anapaya-auth.guard';
import { AnapayaNoAuthGuard } from './anapaya/guard/anapaya-no-auth.guard';
import { SignInComponent } from './anapaya/sign-in/sign-in.component';
import { SignOutComponent } from './anapaya/sign-out/sign-out.component';
import { CanLoadAdminGuard } from './modules/anapaya-ui/guard/can-load-admin.guard';
import { NotAuthorizedComponent } from './modules/anapaya-ui/not-authorized/not-authorized.component';
import { SectionResolver } from './modules/anapaya-ui/resolver/section.resolver';
import { SignedHubComponent } from './modules/anapaya-ui/signed-hub/signed-hub.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/form-config'
    {path: '', pathMatch : 'full', redirectTo: 'hub'},

    // Redirect signed in user to the '/form-config'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.

    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'hub',
                component: SignedHubComponent
            }
        ],
        data: {
            layout: 'empty'
        },
    },

    // Auth routes for guests
    {
        path: '',
        canActivate: [AnapayaNoAuthGuard],
        canActivateChild: [AnapayaNoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {
                path: 'sign-in',
                component: SignInComponent
            },
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', component: SignOutComponent},
        ]
    },
    {
        path: 'admin',
        canActivate: [
            AnapayaAuthGuard,
        ],
        canActivateChild: [AnapayaAuthGuard, AdminGuard],
        data: {
            section: 'admin'
        },
        loadChildren: () => import('app/modules/admin/admin.module').then(m => m.AdminModule),
    },
    {
        path: 'not-authorized',
        component: LayoutComponent,
        children: [
            {
                component: NotAuthorizedComponent,
                path: '',
            }
        ]
    },
    // Admin routes
    {
        path       : '',
        canActivate: [AnapayaAuthGuard],
        canActivateChild: [AnapayaAuthGuard],

        component  : LayoutComponent,
        resolve    : {
            _: SectionResolver
        },
        data: {
            section: 'main'
        },
        children   : [
            {
                path: 'company',
                loadChildren: () => import('app/modules/anapaya-ui/anapaya-ui.module').then(m => m.AnapayaUiModule),
                canLoad:[
                    CanLoadAdminGuard
                ],
            },
            {
                path: 'ticket',
                loadChildren: () => import('app/modules/ticket-redirect/ticket-redirect.module').then(m => m.TicketRedirectModule),
                canLoad:[
                    CanLoadAdminGuard
                ],
            },
        ]
    }
];
