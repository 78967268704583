import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from 'app/anapaya/model/company.model';
import { AbstractService } from 'app/anapaya/service/abstract.service';
import { CompanyService } from 'app/anapaya/service/company.service';
import { environment } from 'environments/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResellerService {

  constructor(
    private http: HttpClient,
    private compantService: CompanyService
  ) { }

  list(): Observable<Company[]> {
    return this.http.get<{resellers: Company[]}>(`${environment.apiUrl}/companies/${this.compantService.currentCompany.id}/resellers`, {withCredentials: true}).pipe(
      map(
        response => response.resellers
      )
    );
  }
}
