import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from 'app/anapaya/model/company.model';
import { AbstractService } from 'app/anapaya/service/abstract.service';
import { CompanyService } from 'app/anapaya/service/company.service';
import { UserService } from 'app/anapaya/service/user.service';
import { environment } from 'environments/environment';
import { Observable, Subscription, map } from 'rxjs';
import { PriceListRequest } from '../model/price-list-request.model';
import { AbstractNotifier } from 'app/anapaya/service/abstract-notifier.service';
import { NotificationService } from 'app/anapaya/service/notification.service';
import { DealStatus } from 'app/anapaya/order/model/deal.model';


@Injectable({
  providedIn: 'root'
})
export class PriceListRequestService implements AbstractService<Company>, AbstractNotifier{
  notificationLabels: string[] = ['resellerDealOrder.fixedPriceRequested'];
  public notificationService: NotificationService;
  constructor(
    private http: HttpClient,
    private companyService: CompanyService,
    private userService: UserService,
    notificationService: NotificationService
  ) {
    this.notificationService = notificationService;
  }

  list(
    search?: string,
    sort?: {[key: string]: 'asc'|'desc'},
    offset: number=0, limit: number=20,
    options?: {[key: string]: string}
  ): Observable<{items: PriceListRequest[]; count: number}> {
    let suffix = 'outgoing';
    if (options?.section === 'provider') {
        suffix = 'incoming';
    }
    const params = { limit, offset };

    if(search) {
      params['where[name]']=`$ilike:${search}`;
    }

    //managing sort order
    if(sort) {
      const key = Object.keys(sort)[0];
      params[`order[${key}]`]=sort[key];
    }
    return this.http.get<{providerPriceListRequests: PriceListRequest[]; count: number}>(
      `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/provider-price-list-requests/${suffix}`, {
          withCredentials: true,
          params: params
        })
      .pipe(
        map( result => ({items: result.providerPriceListRequests, count: result.count}))
      );
  }

  listForDeal(dealId: string, section: 'provider'|'reseller'='reseller'): Observable<{items: PriceListRequest[]; count: number}> {
    const suffix = section === 'reseller' ? 'outgoing' : 'incoming';
    return this.http.get<{providerPriceListRequests: PriceListRequest[]; count: number}>(
      `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/provider-price-list-requests/${suffix}?where[resellerDealId]=${dealId}`, {
        withCredentials: true,
      })
    .pipe(
      map( result => ({items: result.providerPriceListRequests, count: result.count}))
    );
  }

  read(requestId: string): Observable<PriceListRequest> {
    return this.http.get<{providerPriceListRequest: PriceListRequest}>(
      `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/provider-price-list-requests/${requestId}`,
      { withCredentials: true }
    ).pipe( map(
      response => response.providerPriceListRequest
    ));
  }

  post(request: PriceListRequest): Observable<PriceListRequest> {
    return this.http.post<{providerPriceListRequest: PriceListRequest}>(
      `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/provider-price-list-requests`,
      request,
      {withCredentials: true}
    ).pipe(
      map( (r) => {
        this.refresh('resellerDealOrder.fixedPriceRequested');
        return r.providerPriceListRequest;
      })
    );
  }

  patch(requestId: string, request: PriceListRequest): Observable<PriceListRequest> {
    return this.http.patch<{providerPriceListRequest: PriceListRequest}>(
      `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/provider-price-list-requests/${requestId}`,
      request,
      {withCredentials: true}
    ).pipe(
      map( r => r.providerPriceListRequest)
    );
  }

  delete(requestId: string): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/end-clients/${requestId}`,
      {withCredentials: true}
    );
  }

  canWrite(): boolean {
    //@todo adujst
    return !this.companyService.currentCompany ? false :
      (this.companyService.currentCompany.permission.admin || this.userService.userPermissionForPath(this.companyService.currentCompany.id, 'NOPE', 'write'));
  }

  canManageIncoming(): boolean {
    return !this.companyService.currentCompany ? false :
      (this.userService.userPermissionForPath(this.companyService.currentCompany.id, 'providerPriceListRequests', 'manageIncoming'));
  }

  canManageOutgoing(): boolean {
    return !this.companyService.currentCompany ? false :
      (this.userService.userPermissionForPath(this.companyService.currentCompany.id, 'providerPriceListRequests', 'manageOutgoing'));
  }

  notify(label: string, value: string[]): void {
    this.notificationService.notify(this, label, value);
  };

  refresh(label?: string): Subscription|undefined {
    if(label === 'resellerDealOrder.fixedPriceRequested') {
      return this.http.get<{providerPriceListRequests: PriceListRequest[]}>(
        `${environment.apiUrl}/companies/${this.companyService.currentCompany.id}/provider-price-list-requests/incoming?where[status]=pending`,
        {withCredentials: true}
        ).pipe(
        map( (response) => {

          this.notify(label, response.providerPriceListRequests.filter(
            opp => [DealStatus.OPPORTUNITY, DealStatus.PROPOSAL_SENT].includes(opp.resellerDeal.status)).map(item => item.id));
          return response.providerPriceListRequests.length ? ''+response.providerPriceListRequests.length : undefined;
        })
      ).subscribe();
    }
    return undefined;
  }
}
