import { Injectable } from '@angular/core';
import { mergeMap, map, Observable, of, BehaviorSubject, mapTo } from 'rxjs';
import { Contact } from '../model/contact.model';
import { Permission } from '../model/permission.model';
import { CompanyService } from './company.service';
import { PermissionService } from './permission.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _user: BehaviorSubject<Contact|undefined> = undefined//;new BehaviorSubject<Contact>(1);
  private _permissions:{[key: number]: Permission} = {};
  private _permissionGettingSubscriptions: {[key: number]: Observable<Permission>} = {};
  private _userIsAdmin=false
  private _isIdentityAssumed = false;

  constructor(
    private permissionService: PermissionService,
    private companyService: CompanyService
  ) {
    this._user=new BehaviorSubject(undefined)
   }

  set user(user: Contact|undefined) {
    if (user === undefined) {

      this._permissions = {};
      this._permissionGettingSubscriptions = {};
      this._user.next(user);
      this.companyService.clearCompanies();
      
    } else if(this._user===undefined) {
      this._user=new BehaviorSubject(user)
    } else {
      this._user.next(user);
    }
  }

  get isAdmin(): boolean {
    return this._userIsAdmin
  }

  set isAdmin(isAdmin: boolean) {
    this._userIsAdmin = isAdmin;
  }


  set isIdentityAssumed(assumed: boolean) {
    this._isIdentityAssumed = assumed;
  }

  get isIdentityAssumed() {
    return this._isIdentityAssumed;
  }

  /**
   * queries backend for user permissions per company. stores them locally.
   * @param companyId 
   * @returns 
   */
  userPermissions(companyId: string): Observable<Permission|undefined> {

    return this.companyService.companies.pipe(
      map(companies => {
        let permission = undefined;

        companies.forEach(company => {
          this._permissions[company.id]=permission
          if(company.id === companyId) {
            permission = company.permission;
          }
        })
        return permission;
      })
    )

  }

  /**
   * Checks user permission for a given permission path (through features permission object), given a mode (read or write)
   * @param companyId 
   * @param path 
   * @param mode 
   * @returns 
   */
  userPermissionForPath(companyId: string|undefined, path: string|{[key: string]: string[]}, mode: "read"|"write"|"activate"|string):boolean {
    let pathObj: {[key: string]: string[]};

    if(typeof path === "string") {
      pathObj = {or: [path]}
    } else {
      pathObj = path
    }

    let permissions = this._permissions[companyId ?? this.companyService.currentCompany.id] ?? this.companyService.currentCompany?.permission;

    return permissions ? (this.permissionService.checkPermission(permissions, pathObj, mode)) : false
  }

  /**
   * 
   * @param path 
   * @param mode 
   */
  userMasterPermissionForPath(path: string|{[key: string]: string[]}, mode: "read"|"write"): Observable<boolean> {
    return this.companyService.companies.pipe( map(
      companies => {
        let result = false;
        companies.forEach( comp => {
          if (comp.isMasterCompany) {
            result = this.userPermissionForPath(comp.id, path, mode);
          }
        });

        return result;
      }
    )
    );
  }

  get user$(): Observable<Contact>
  {
    return this._user.asObservable();
  }

  update(param: any): Observable<any> {
    return of({})
  }

  /**
   * 
   * @param companyId 
   * @param section 
   * @param subsection 
   * @returns 
   */
  canAccess(companyId: string|undefined, section?: string, subsection?: string): Observable<boolean> {
    return companyId ?
      this.userPermissions(companyId).pipe( map( perms => {
        return perms && (perms.admin || Object.keys(perms.features).includes(section) && perms.features[section].read === true)
      })) : of(false);
  }

  hasAtLeastOneAccess(companyId): Observable<boolean> {
    return this.userPermissions(companyId).pipe(
      map(
        this.permissionService.atLeastOneAccess
      )
    )
  }
}
