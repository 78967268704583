import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './sign-in/sign-in.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { SignOutComponent } from './sign-out/sign-out.component';
import { FuseAlertModule } from '@fuse/components/alert';
import { PermissionEditComponent } from './permission-edit/permission-edit.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { UserViewComponent } from './user-view/user-view.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { CompanyChooserComponent } from './company-chooser/company-chooser.component';
import { ContactEditComponent } from './contact-edit/contact-edit.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { FuseConfirmationModule } from '@fuse/services/confirmation';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatListModule} from '@angular/material/list';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CompanySearchComponent } from './company-search/company-search.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import { SearchBarComponent } from './search-bar/search-bar.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { SimpleSearchComponent } from './simple-search/simple-search.component';
import { AdminContactListComponent } from './admin-contact-list/admin-contact-list.component';
import { MatSortModule } from '@angular/material/sort';
import { FormatFileSizePipe } from './utils/file-size.pipe';
import { ChCurrencyPipe } from './utils/ch-currency.pipe';
import { TruncatePipe } from './utils/truncate.pipe';

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  declarations: [
    SignInComponent,
    SignOutComponent,
    PermissionEditComponent,
    UserViewComponent,
    ContactListComponent,
    CompanyChooserComponent,
    ContactEditComponent,
    UserMenuComponent,
    CompanySearchComponent,
    SearchBarComponent,
    SimpleSearchComponent,
    AdminContactListComponent,
    FormatFileSizePipe,
    ChCurrencyPipe,
    TruncatePipe
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatIconModule,
    MatSortModule,
    RouterModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    FuseAlertModule,
    MatMenuModule,
    MatDividerModule,
    FuseConfirmationModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatListModule,
    MatChipsModule,
    MatCheckboxModule
  ],
  exports: [
    SignInComponent,
    PermissionEditComponent,
    UserMenuComponent,
    SearchBarComponent,
    SimpleSearchComponent,
    FormatFileSizePipe,
    ChCurrencyPipe,
    TruncatePipe
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT }
  ]
})
export class AnapayaModule { }
