import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { AnapayaNavigationService } from 'app/anapaya/service/navigation.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SectionResolver implements Resolve<boolean> {
  constructor(
    private navigationService: AnapayaNavigationService,
   
  ) {
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    
    if(route.data.section) {
      this.navigationService.section = route.data.section;
    }
    return of(true);
  }
}
