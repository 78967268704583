<form [formGroup]="filterForm">
    <mat-form-field appearance="fill" class="w-full" [floatLabel]="true"
        class="mat-mdc-form-field fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50 mat-mdc-form-field-type-mat-input mat-mdc-form-field-has-icon-prefix mat-form-field-appearance-fill mat-primary">
        <mat-icon matPrefix>search</mat-icon>
       
        <input
            matInput
            placeholder="{{placeholder}}"
            class="py-0 h-10 my-0"
            [formControlName]="'filterSearch'"
            >
        
    </mat-form-field>
</form>
