<div class="pb-12 w-full">
    <div class="items-start justify-between py-8 px-8">
        <div class="text-4xl font-extrabold tracking-tight leading-none">Members</div>
        <div class="ml-0.5 font-medium text-secondary">
            {{_length}} member<ng-container *ngIf="_length > 1">s</ng-container>
        </div>
        <div class="w-full flex gap-4 items-center py-5">
            <form [formGroup]="searchForm" class="w-full">
                <anapaya-simple-search [formControlName]="'filterSearch'" class="w-3/4" [placeholder]="placeholder"></anapaya-simple-search>
            </form>
           
        </div>
    </div>
    <table mat-table [dataSource]="contacts$" class="w-full" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>
                ID
            </th>
            <td mat-cell *matCellDef="let element"> {{element.id}}
            </td>
        </ng-container>
        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="firstName">
                First name
            </th>
            <td mat-cell *matCellDef="let element"> {{element.firstName}}
            </td>
        </ng-container>
        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef class="max-w-50"  mat-sort-header="lastName">
                Last name
            </th>
            <td mat-cell *matCellDef="let element"> {{element.lastName}}
            </td>
        </ng-container>
        <ng-container matColumnDef="admin">
            <th mat-header-cell *matHeaderCellDef class="w-20">
                
            </th>
            <td mat-cell *matCellDef="let element" >
                <ng-container *ngIf="element.permission.admin">
                    <span class="inline-bloc rounded-full px-2.5 py-1 h-7 text-sm w-40 bg-warn-400" >
                        Admin
                    </span>
                </ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
                Actions
            </th>
            <td mat-cell *matCellDef="let element" class="p-2"> 
                <button mat-mini-fab color="basic" class="ml-2" (click)="impersonate(element.id)" [disabled]="isSelf(element.id)"><mat-icon svgIcon="heroicons_outline:arrow-circle-right"></mat-icon></button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class=""></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="bg-card hover:bg-gray-100 dark:transparent dark:hover:bg-hover"></tr>
    </table> 
    <mat-paginator [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        class="dark:bg-default-50"
        [pageSize]="_pageSize"
        [pageIndex]="_pageIndex"
        (page)="pageEvent($event)"
        [length]="_length"
        aria-label="Select page">
    </mat-paginator>
</div>
