<mat-drawer-container (backdropClick)="closeAndBack()" class="w-full bg-card dark:bg-transparent">
    <mat-drawer-content>
<div class="flex-auto">   
    <div class="items-start justify-between py-8 px-8">
        <div class="text-4xl font-extrabold tracking-tight leading-none">Companies</div>
        <div class="ml-0.5 font-medium text-secondary">
            {{_length}} compan<ng-container *ngIf="_length > 1">ies</ng-container><ng-container *ngIf="_length < 2">y</ng-container>
        </div>
        <div class="w-full flex gap-4 items-center py-5">
            <form [formGroup]="searchForm" class="w-full">
                <anapaya-simple-search [formControlName]="'filterSearch'" class="w-3/4" [placeholder]="placeholder"></anapaya-simple-search>
            </form>
        </div>
    </div>
    <table mat-table [dataSource]="companies" class="w-full">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>
                ID
            </th>
            <td mat-cell *matCellDef="let element" (click)="openDrawer(element.id)">{{element.id}}
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
                Name
            </th>
            <td mat-cell *matCellDef="let element" (click)="openDrawer(element.id)" > {{element.name}}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover:bg-gray-100  dark:hover:bg-gray-700"></tr>
    </table>
</div>

</mat-drawer-content>
<mat-drawer #drawer
    [mode]="'over'"
    [position]="'end'"
    (keydown.escape)="closeAndBack()"
    class="w-full sm:w-140 md:w-160 xl:w-192 xxl:w-200 dark:bg-gray-700 bg-accent-100"
    >
    <div class="p-4" class="w-full sm:w-140 md:w-160 xl:w-192 xxl:w-200">
        <div class="relative h-20 sm:h-20 px-8 sm:px-12">
            <div class="flex items-center justify-end sm:justify-start w-full max-w-3xl mx-auto pt-6">
                <button 
                    mat-icon-button="" 
                    class="mat-mdc-tooltip-trigger mdc-icon-button mat-mdc-icon-button mat-unthemed mat-mdc-button-base tex-primary"
                    
                    (click)="closeAndBack()"
                    >
                    <span class="mat-button-wrapper" class="" >
                        <mat-icon svgIcon="heroicons_outline:x"></mat-icon>
                    </span>
                </button>
            </div>
        </div>
        <div>
            <router-outlet name="contacts"></router-outlet>
        </div>
    </div>
</mat-drawer>
</mat-drawer-container>